import {Model} from '../framework/services/jsonapi/common';
import {AbstractResourceModel} from "./common/abstract-resource-model";

@Model('tokens')
export class TokenModel extends AbstractResourceModel {
  public email: string = null;
  public password: string = null;
  public jwt: string = null;
  public expiresAt: string = null;

  constructor() {
    super('/api/tokens');
  }
}
