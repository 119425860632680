import {ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'pd-table-group',
  template: require('./table-group.component.html'),
  styles: [require('./table-group.component.scss')],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableGroupComponent {
  @ContentChild(TemplateRef, {read: TemplateRef})
  public templateForGroup: TemplateRef<any>;
}