import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ModalWindowService} from "./services/modalWindow/modal-window.service";
import {AccessRightService} from "./framework/services/resource/access-right.service";
import {AccountSettingsService} from "./services/account-settings.service";


@Component({
  selector: 'baza',
  template: require('./app.component.html'),
  styles: [require('./app.component.less')]
})

export class AppComponent implements OnInit {
  @ViewChild('modalWindowComponent', {read: ViewContainerRef}) modalWindow: ViewContainerRef;

  constructor(public translationService: TranslateService,
              private modalService: ModalWindowService,
              private resource: AccessRightService,
              private accountSettingsService: AccountSettingsService) {
  }

  ngOnInit(): void {
    this.translationService.setDefaultLang(this.accountSettingsService.getCurrentLanguage() || 'pl');
    this.resource.reloadPermissions();
    this.modalService.initModalComponent(this.modalWindow);
  }

}

