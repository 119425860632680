import {Component, OnInit} from '@angular/core';
import {AccountModel} from "baza/app/transferObject";
import {ResourceUrlService} from "baza/app/services/resource-url.service";
import {NetworkJsonApiService} from "baza/app/framework/services/jsonapi/network-jsonapi.service";
import {FormControl} from "@angular/forms";
import {debounceTime} from "rxjs/internal/operators";
import {Router} from "@angular/router";
import {AccessRightService} from "baza/app/framework/services/resource/access-right.service";

@Component({
  selector: 'pd-main-search',
  template: require('./main-search.component.html'),
  styles: [require('./main-search.component.scss')]
})

export class MainSearchComponent implements OnInit {

  private static MIN_SEARCH_PHRASE_LENGTH = 2;
  public searchPhrase: FormControl = new FormControl();

  public accounts: AccountModel[] = [];
  public highlightedIndex: number = 0;

  constructor(private networkService: NetworkJsonApiService, private router: Router, private accessRight: AccessRightService) {
  }

  ngOnInit() {
    this.initFilter();
  }

  initFilter() {
    this.searchPhrase.valueChanges
      .pipe(
        debounceTime(400)
      )
      .subscribe(phrase => {
        if (phrase.length >= MainSearchComponent.MIN_SEARCH_PHRASE_LENGTH) {
          this.loadData(phrase);
        }
        else {
          this.clearData();
        }
      });
  }





  loadData(phrase: string) {
    if(this.accessRight.hasRight('policy/groups/main_search/account.see')) {
      this.loadAccounts(phrase);
    }

  }

  loadAccounts(phrase: string) {
    // this.networkService.getResources(ResourceUrlService.EMPLOYEES_PATH + '/search/' + `?phrase=${phrase}`).subscribe((accounts: AccountModel[]) => {
    //   this.accounts = accounts;
    // });
  }



  redirectTo(routeName: string, id: string) {
    this.clearData();
    this.resetPhrase();
    this.router.navigate([routeName, id]);
  }

  selectAccount(account) {
    this.redirectTo('accounts', account.id);
  }



  clearData() {
    this.accounts = [];
    this.highlightedIndex=0;
  }

  resetPhrase() {
    this.searchPhrase.patchValue('');
  }

}
