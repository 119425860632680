import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'pd-modal-window-with-confirmation-msg',
  template: require('./confirmation-msg.component.html'),
  styles: [require('./confirmation-msg.component.scss')]
})
export class ConfirmationMsgComponent {

  @Output()
  public confirmation = new EventEmitter();

  @Output()
  public rejection = new EventEmitter();

  @Input()
  public title: string;

  @Input()
  public msgKey: string;

  @Input()
  public msgArgs: object;

  @Input()
  public data: any;

  actionWhenUserClickedYes() {
    this.confirmation.next(this.data);
  }

  actionWhenUserClickedNo() {
    this.rejection.next(this.data);
  }

  setComponentProperties<T>(data?: T, title?: string, content?: string, msgArgs?: object) {
    this.title = title;
    this.msgKey = content;
    this.msgArgs = msgArgs;
    this.data = data;
  }
}
