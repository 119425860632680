import {MockResourceLifecycleListener} from "../../framework/components/form/abstract-resource-editor/mock-resource-lifecycle-listener";

export class ResourceEditorLifecycleListener extends MockResourceLifecycleListener<any> {
  constructor(private savingWithSuccessCallback: () => void) {
    super();
  }

  public afterSavingModelOnServerWithSuccess(model: any) {
    this.savingWithSuccessCallback();
  }
}