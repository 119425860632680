import {CanActivate} from "@angular/router";
import {Injectable} from "@angular/core";
import {AccountRoleService} from "../services/account-role.service";

@Injectable()
export class OnlyManagementGuard implements CanActivate {

  constructor(private accountRoleService: AccountRoleService) { }

  canActivate() {
    return true;
  }
}