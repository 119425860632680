import {Component, DoCheck, Input, OnInit} from "@angular/core";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'pd-form-validation-msg',
  template: require('./form-validation-msg.component.html')
})
export class FormValidationMsgComponent implements OnInit, DoCheck {
  public static readonly VALIDATION_ERR_MSG_PREFIX = 'ServerError';
  private static readonly VALIDATION_MSG_PREFIX = 'validationMsgFor';

  @Input() errors: any;


  displayValidationMsg = false;

  errorType = null;
  errorParams = null;

  constructor(private ngForm: NgForm) {
  }

  ngDoCheck() {
    let tuple: { key: string, value: object } = this.getErrorTypeAndParams(this.errors);
    this.errorType = tuple.key;
    this.errorParams = tuple.value;
  }

  ngOnInit() {
    this.ngForm.ngSubmit.subscribe(() => {
      this.displayValidationMsg = true;
    });
  }

  getErrorTypeAndParams(errors: { [key: string]: any }): { key: string, value: object } {

    this.errorType = null;
    this.errorParams = null;

    if (!this.errors) {
      return {key: null, value: null};
    }
    let keys = Object.keys(errors);
    if (keys.length) {
      return {key: FormValidationMsgComponent.VALIDATION_MSG_PREFIX + keys[0], value: errors[keys[0]]};
    }
  }
}
