import {Pipe, PipeTransform} from '@angular/core';
import * as moment from "moment";
import {LongTimeFormatter} from "baza/app/framework/components/form/form-time-spent/long-time-formatter";

@Pipe({
  name: 'longTimeFormatter'
})

export class LongTimeFormatterPipe implements PipeTransform {

  transform(time: number): string {
    let formatter: LongTimeFormatter = new LongTimeFormatter;
    return formatter.formatValue(time, 3);
  }



}
