import {NG_VALUE_ACCESSOR, NgForm} from "@angular/forms";
import {Component, Input} from "@angular/core";
import {ElementBase} from "../abstractComponent/element-base";


@Component({
  selector: 'pd-form-input',
  template: require('./form-input.component.html'),
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: FormInputComponent, multi: true}
  ]
})
export class FormInputComponent extends ElementBase<string> {

  @Input()
  public type: string = 'text';
  @Input()
  public min: number;
  @Input()
  public max: number;
  @Input()
  public step: number = 1;
  @Input()
  public disabled: boolean = false;
  @Input()
  public required: boolean = false;

  constructor(public formDirective: NgForm) {
    super(formDirective);
  }
}
