export const typesMap: {[key in string] : any} = {};


export interface Typed {
  getType(): string;
}

export class Link {
  public meta: { active: boolean } = {active: false};

  constructor(public href: string, active: boolean) {
    this.meta.active = active;
  }
}

export function Model(typeName: string) {
  return function classDecorator<T extends { new(...args: any[]): {} }>(constructor: T) {
    const decorated = class extends constructor implements Typed {
      id: string = '' + Math.floor(Math.random() * -100000);
      getType(): string {
        return typeName;
      }
    };

    typesMap[typeName] = decorated;
    return decorated;
  };
}
