import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {NetworkJsonApiService} from "../../framework/services/jsonapi/network-jsonapi.service";
import {Router} from "@angular/router";
import {NavbarService} from "../../services/navbar.service";
import {OnlyManagementGuard} from "../../guards/only-management.guard";
import {AccessRightGuard} from "../../guards/access-right.guard";

@Component({
  selector: 'app-navbar',
  template: require('./navbar.component.html'),
  styles: [require('./navbar.component.scss')]
})

export class NavbarComponent implements OnInit {

  constructor(private networkService: NetworkJsonApiService, private router: Router, private authService: AuthService,
              private navbarService: NavbarService, public managementGuard: OnlyManagementGuard, public accessRightGuard: AccessRightGuard) {
  }

  ngOnInit() {
  }

  logout() {
    this.authService.logout();
  }

  isUserLogged(): boolean {
    return true;
  }

}
