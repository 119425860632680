import {ComponentFactory, ComponentFactoryResolver, Injectable, Injector, Type} from "@angular/core";

import {ComponentRef} from "@angular/core/src/linker/component_factory";
import {AbstractResourceEditor} from "./abstract-resouce-editor";


@Injectable()
export class CustomComponentFactory {


  constructor(private factory: ComponentFactoryResolver, private injector: Injector) {
  }

  public createEditor(component: Type<AbstractResourceEditor<any>>, resource: any): ComponentRef<AbstractResourceEditor<any>> {
    const componentRef: ComponentRef<AbstractResourceEditor<any>> = this.create(component);
    componentRef.instance.resource = resource;
    return componentRef;
  }

  create<T>(component: Type<T>) {
    const componentFactory: ComponentFactory<T> = this.factory.resolveComponentFactory(<any>component);
    const componentRef: ComponentRef<T> = componentFactory.create(this.injector);
    return componentRef;
  }
}
