import * as _ from 'lodash';
import {Typed} from './common';

export interface IdType {
  id: string;
  type: string;
}

export interface Relationships {
  [name: string]: {
    data: IdType | IdType[]
  };
}

export interface Links {
  [name: string]: {
    href: string;
    meta: {};
  };
}

export interface JsonApiData extends IdType {
  attributes?: {};
  relationships?: Relationships;
  links?: Links;
  meta? : { [key: string]: Object };
}

export class JsonApiResource {
  data: JsonApiData | JsonApiData[];
  included?: JsonApiData[];
  links?: Links;
  meta?: { [key: string]: Object };

  constructor(data: JsonApiData | JsonApiData[], included?: JsonApiData[], links?: Links) {
    this.data = data;
    this.included = included;
    this.links = links;
  }
}

export abstract class JsonApiWrapper<T extends JsonApiData> implements Typed {
  protected included: JsonApiData[];
  protected links: Links;
  protected meta: { [key: string]: Object };
  protected primaryData: T | T[];

  constructor(o: JsonApiResource) {
    this.included = [];
    this.links = {};
    this.meta = {};
    if(o) {
      this.included = o.included;
      this.links = this.normalizeLinks(o.links);
      this.meta = o.meta;
    }
  }

  private normalizeLinks(links: Links) {
    let normalizedLinks = {};
    _.forEach(links, (link, key: string) => {
      if (_.isString(link)) {
        normalizedLinks[key] = {href: link};
      }
      else {
        normalizedLinks[key] = link;
      }
    });
    return normalizedLinks;
  }


  static of<T extends JsonApiData>(resource: JsonApiResource): JsonApiWrapper<T> {
    if (resource && (resource.data instanceof Array)) {
      return new JsonApiVectorWrapper<T>(resource);
    } else {
      return <JsonApiWrapper<T>> new JsonApiScalarWrapper<T>(resource);
    }

  }

  toWrapper<D extends JsonApiData>(data: JsonApiData): JsonApiScalarWrapper<D> {
    const primaryData = data;
    const links = this.links;
    const included = this.included.concat(this.primaryData);
    const resource = new JsonApiResource(primaryData, included, links);
    return <JsonApiScalarWrapper<D>>new JsonApiScalarWrapper(resource);
  }

  isMultiResource() {
    return this instanceof JsonApiVectorWrapper;
  }

  abstract toScalarWrappers(): JsonApiScalarWrapper<T>[];

  getType(): string {
    const data = this.primaryData;
    if (this.isIdTypeScalar(data)) {
      return data.type;
    } else {
      if (data.length > 0) {
        return data[0].type;
      } else {
        return '';
      }
    }
  }

  getRelatedOf<D extends JsonApiData>(data: JsonApiData, relationName: string): D[] {
    const relIds = this.getRelIds(data, relationName);
    const allData = this.included ? this.included.concat(this.primaryData) : <T[]>this.primaryData;

    return <D[]>_.intersectionWith(allData, relIds, function (inc: IdType, rel: IdType) {
      return rel.id === inc.id && rel.type === inc.type;
    });
  }

  getIncluded<D extends JsonApiData>(type: string): D[] {
    return <D[]>_.filter(<any>this.included, {type: type});
  }

  getAllIncluded<D extends JsonApiData>(): D[] {
    return <D[]>this.included;
  }

  getIncludedById<D extends JsonApiData>(type: string, id: string): D {
    return <D>_.find(<any>this.included, {type: type, id: id});
  }

  addIncluded(inc: JsonApiData) {
    this.included.push(inc);
  }

  getJsonApiResource(): JsonApiResource {
    return new JsonApiResource(this.primaryData, this.included, this.links);
  }

  getLinks(): Links {
    return this.links;
  }

  getMeta(): any {
    return _.mapKeys(this.meta, (value, key: string) => {
      return _.camelCase(key);
    });
  }

  getMetaAttributesArrayByName<D>(name: string): D[] {
    return <D[]>this.meta[name];
  }

  protected getRelIds(data: JsonApiData, relationName: string): IdType[] {
    const relData = data.relationships[relationName].data;
    if (relData === null || relData === undefined) {
      return [];
    }
    if (this.isIdTypeScalar(relData)) {
      return [relData];
    } else {
      return relData;
    }
  }

  protected isIdTypeScalar(t: IdType | IdType[]): t is IdType {
    return (<IdType>t).id !== undefined;
  }
}


export class JsonApiScalarWrapper<T extends JsonApiData> extends JsonApiWrapper<T> {
  protected primaryData: T;

  constructor(o: JsonApiResource) {
    super(o);
    let data = <T>{};
    if(o) {
      data = <T>o.data;
    }
    this.primaryData = data;
  }

  toScalarWrappers(): JsonApiScalarWrapper<T>[] {
    return [this];
  }

  getRelated<D extends JsonApiData>(relationName: string): D[] {
    return this.getRelatedOf<D>(this.primaryData, relationName);
  }

  getData(): T {
    return this.primaryData;
  }

  getMeta() {
    return this.meta;
  }
}

class JsonApiVectorWrapper<T extends JsonApiData> extends JsonApiWrapper<T> {
  protected primaryData: T[];

  constructor(o: JsonApiResource) {
    super(o);
    this.primaryData = <T[]>o.data;
  }

  toScalarWrappers(): JsonApiScalarWrapper<T>[] {
    const resource = this.getJsonApiResource();
    return <JsonApiScalarWrapper<T>[]>_.map(this.primaryData, data => {
      const included = resource.included ? resource.included.concat(this.primaryData) : this.primaryData;
      const newResource = Object.assign({}, resource, {data: data, included: included});
      return JsonApiWrapper.of<T>(newResource);
    });

  }

  // getRelated<D extends JsonApiData>(dataId: string, relationName: string): D[] {
  //     let that = this;
  //     let relIds = _(this.primaryData).filter(<T>{ id: dataId }).flatMap(function(d: JsonApiData) {
  //         return that.getRelIds(d, relationName);
  //     }).value();
  //     return <D[]>_.intersectionWith(this.included, relIds, function(inc: IdType, rel: IdType) {
  //         return rel.id === inc.id && rel.type === inc.type;
  //     });
  // }

  getData(): T[] {
    return this.primaryData;
  }

}
