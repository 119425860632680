import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pd-tooltip',
  template: require('./tooltip.component.html'),
  styles: [require('./tooltip.component.scss')]
})
export class TooltipComponent implements OnInit {

  @Input() title: string;
  @Input() position: string;

  constructor() { }

  ngOnInit() {
  }

}
