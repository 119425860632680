import {ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef, Type} from '@angular/core';
import {AbstractResourceEditor} from "../../form/abstract-resource-editor/abstract-resouce-editor";

@Component({
  selector: 'pd-table-action',
  template: require('./table-action.component.html'),
  styles: [require('./table-action.component.scss')],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableActionComponent {

  @Input()
  public editorType: Type<AbstractResourceEditor<any>>;

  @Input()
  public editAction: boolean = true;

  @Input()
  public deleteAction: boolean = true;

  @ContentChild(TemplateRef, {read: TemplateRef})
  public templateForAction: TemplateRef<any>;
}