import {AbstractConverter} from "./AbstractConverter";
import * as moment from "moment";
import * as _ from "lodash";


export class TimeConverter implements AbstractConverter<string, Date> {

  public static readonly SERVER_TIME_FORMAT = 'HH:mm';
  public static readonly VIEW_TIME_FORMAT = 'HH:mm';

  constructor(private timeFormat: string) {
  }

  convertToType(value: string): Date {
    const dateWrapper = moment(_.trim(value), this.timeFormat, true);
    if (dateWrapper.isValid()) {
      return dateWrapper.toDate();
    }
    return null;
  }

  formatValue(value: Date): string {
    if (!value) {
      return null;
    }
    return moment(value).format(this.timeFormat);
  }

  public isValidDate(value: string) {
    return moment(_.trim(`${value}`), this.timeFormat, true).isValid();
  }

  public static formatForJson(): TimeConverter {
    return new TimeConverter(TimeConverter.SERVER_TIME_FORMAT);
  }

  public static formatForView(): TimeConverter {
    return new TimeConverter(TimeConverter.VIEW_TIME_FORMAT);
  }
}