import {Component, EventEmitter, Input, ViewChild, ViewContainerRef, ViewRef} from '@angular/core';
import {Subscription} from "rxjs";

@Component({
  selector: 'pd-modal-window',
  template: require('./modal-window.component.html'),
  styles: [require('./modal-window.component.scss')]
})
export class ModalWindowComponent {


  @Input()
  size: 'small' | 'default' = 'default';
  @ViewChild('contentContainer', {read: ViewContainerRef})
  private contentContainer: ViewContainerRef;
  public displaySaveButton: boolean = false;

  public insertComponent(view: ViewRef) {
    this.contentContainer.insert(view);
  }

}
