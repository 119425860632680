import * as jwtDecode from 'jwt-decode';
import {NetworkJsonApiService} from "../framework/services/jsonapi/network-jsonapi.service";
import {TokenModel} from "../transferObject/TokenModel";
import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import {Router} from "@angular/router";
import {AccessRightService} from "../framework/services/resource/access-right.service";

type BazaOfertToken = { 'account_id': string };

@Injectable()
export class AuthService {

  static TOKEN_KEY: string = 'bazaOfertTokenJwt';
  static TOKEN_SELF_URL_KEY: string = 'bazaOfertTokenSelfUrl';
  static TOKEN_EXPIRE_TIME_KEY: string = 'bazaOfertTokenExpireTime';
  static ROLE_NAME: string = 'role_name';

  constructor(private networkService: NetworkJsonApiService, private router: Router, private accessRight: AccessRightService) {
  }

  storeToken(url: string, token: string, expiresAt: string) {
    localStorage.setItem(AuthService.TOKEN_KEY, token);
    localStorage.setItem(AuthService.TOKEN_SELF_URL_KEY, url);
    localStorage.setItem(AuthService.TOKEN_EXPIRE_TIME_KEY, expiresAt);
  }

  getToken(): string {
    return localStorage.getItem(AuthService.TOKEN_KEY);
  }

  getTokenSelfUrl(): string {
    return localStorage.getItem(AuthService.TOKEN_SELF_URL_KEY);
  }

  getTokenExpireTime(): string {
    return localStorage.getItem(AuthService.TOKEN_EXPIRE_TIME_KEY);
  }

  deleteTokenWithSelfUrl() {
    localStorage.removeItem(AuthService.TOKEN_KEY);
    localStorage.removeItem(AuthService.TOKEN_SELF_URL_KEY);
    localStorage.removeItem(AuthService.TOKEN_EXPIRE_TIME_KEY);
  }

  clearAccessRights() {
    this.accessRight.clearRightsCache();
  }

  getTokenData(): object {
    if (this.getToken()) {
      return <BazaOfertToken>jwtDecode(this.getToken());
    } else {
      return {};
    }
  }

  getFromTokenData(key: string): any {
    return this.getTokenData()[key];
  }

  isLogendIn(): Observable<boolean> {
    if (this.getFromTokenData('account_id') && this.timeToEnd() > new Date()) {
      return of(true);
    } else {
      return this.networkService.getResource(this.getTokenSelfUrl())
        .pipe(
          map((model: TokenModel) => {
            this.deleteTokenWithSelfUrl();
            this.storeToken(model.links.self.href, model.jwt, model.expiresAt);
            return true;
          }),
          catchError(() => {
            this.storeRoleName();
            this.deleteTokenWithSelfUrl();
            return of(false);
          })
        );
    }
  }

  storeRoleName() {
    localStorage.setItem(AuthService.ROLE_NAME, this.getCurrentRoleName());
  }

  getStoredRoleName(): string {
    return localStorage.getItem(AuthService.ROLE_NAME);
  }

  timeToEnd(): Date {
    let minutes = 5 * 1000 * 60;
    return new Date(new Date(this.getTokenExpireTime()).getTime() - minutes);
  }

  getCurrentAccountId(): string {
    return this.getFromTokenData('account_id');
  }

  getCurrentEmpoyeeFirstName(): string {
    return this.getFromTokenData('first_name');
  }

  getCurrentEmpoyeeLastName(): string {
    return this.getFromTokenData('last_name');
  }

  getCurrentRoleName(): string {
    return this.getFromTokenData('role_name');
  }

  logout() {
    this.networkService.deleteResource(this.getTokenSelfUrl()).subscribe(() => {
      this.router.navigate(['login']);
    });
    this.deleteTokenWithSelfUrl();
    this.clearAccessRights();
  }
}

