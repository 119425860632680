import {Pipe, PipeTransform} from '@angular/core';
import {DateConverter} from "../../services/jsonapi/converters/attributes/DateConverter";

@Pipe({
  name: 'dateFormatter'
})
export class DateFormatterPipe implements PipeTransform {

  private dateConverter: DateConverter = DateConverter.formatForView();

  transform(value: Date, args?: any): any {
    return this.dateConverter.formatValue(value, args);
  }

}
