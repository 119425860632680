import {Component} from '@angular/core';
import {AbstractResourceEditor} from "../../framework/components/form/abstract-resource-editor/abstract-resouce-editor";
import {NetworkJsonApiService} from "../../framework/services/jsonapi/network-jsonapi.service";
import {TokenModel} from "../../transferObject/TokenModel";
import {AuthService} from "../../services/auth.service";
import {Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AccessRightService} from "../../framework/services/resource/access-right.service";
import * as _ from 'lodash';
import {AccountSettingsService} from "../../services/account-settings.service";
import {DefaultRoute} from "../../app.routes";

@Component({
  selector: 'pd-tokens',
  template: require('./tokens.component.html'),
  styles: [require('./tokens.component.scss')]
})

export class TokensComponent extends AbstractResourceEditor<TokenModel> {
  constructor(protected networkService: NetworkJsonApiService, private authService: AuthService, private router: Router,
              private route: ActivatedRoute, private accessRightService: AccessRightService,
              private accountSettingService: AccountSettingsService) {
    super(networkService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.authService.deleteTokenWithSelfUrl();
  }

  private invalidLogin = false;

  public afterSavingModelOnServerWithSuccess(model: TokenModel): Observable<TokenModel> {
    let observable: Observable<TokenModel> = super.afterLoadingModelFromServerWithSuccess(model);
    this.authService.storeToken(model.links.self.href, model.jwt, model.expiresAt);
    this.accessRightService.reloadPermissions();
    this.accountSettingService.loadSettings();
    this.redirect();
    return observable;
  }

  getUrlToRedirect(): string {
    if(this.authService.getCurrentRoleName() == this.authService.getStoredRoleName()) {
      return this.route.snapshot.queryParams.next;
    }
    else{
      return DefaultRoute;
    }
  }

  public afterSavingModelOnServerWithError(error: any) {
    let observable: Observable<any> = super.afterSavingModelOnServerWithError(error);
    this.invalidLogin = true;
    return observable;
  }

  private redirect() {
    let url = this.getUrlToRedirect();
    if(!_.isEmpty(url)){
      this.router.navigateByUrl(url);
    } else {
      this.router.navigate(['']);
    }
  }
}
