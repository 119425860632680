import {Link} from '../../framework/services/jsonapi/common';
import * as _ from "lodash";

//Verify if we need this
export class AbstractResourceModel {
  public id: string;
  public type: string;

  public links: { self: Link, edit: Link, delete: Link } = {self: null, edit: null, delete: null};
  public meta: { [key: string]: Object };

  constructor(self: string) {
    this.links.self = new Link(self, true);
  }

  canBeEdited() {
    if (this.isNew()){
      return true;
    }
    return this.canBeExecuted('edit');
  }

  canBeDeleted() {
    if (this.isNew()){
      return false;
    }
    return this.canBeExecuted('delete');
  }

  canBeExecuted(linkType: string) {
    return !!(_.property(`links.${linkType}.meta.active`)(this));
  }

  isNew() {
    return Number(this.id) < 0;
  }
}
