import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from "@angular/common";

@Pipe({
  name: 'attributeValueFormatter'
})

export class AttributeValueFormatterPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {

  }

  transform(value: any, args?: any): any {
    if (typeof  value === 'number') {
      var digits = (args == null ? "1.2" : args);
      return this.decimalPipe.transform(value, digits);
    }
    return value;
  }
}
