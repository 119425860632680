export class Metadata {


  static setMeta(target: any, name: string, value: any) {
    Reflect.defineMetadata(name, value, target);
  }

  static getMeta(target: any, name: string, defaultValue ?: any): any {
    const meta = Reflect.getMetadata(name, target);
    if (meta) {
      return meta;
    }
    return defaultValue ? defaultValue : meta;
  }

  static isMetaSet(target: any, name: string) {
    return !!Reflect.getMetadata(name, target);

  }
}