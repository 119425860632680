import {Directive, HostListener} from '@angular/core';
import {ModalWindowService} from "../../../services/modalWindow/modal-window.service";

@Directive({
  selector: '[pdModalWindowCloseButton]'
})
export class ModalWindowCloseButtonDirective {

  constructor(private modalWindowService: ModalWindowService) {
  }

  @HostListener('click')
  closeModal() {
    this.modalWindowService.hideModalWithClick();
  }
}
