import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], callback: any): any[] {
    if(!items) {
      return [];
    }
    return items.filter(item => callback(item));
  }

}
