import {Component} from '@angular/core';
import {NG_VALUE_ACCESSOR, NgForm} from "@angular/forms";
import {ElementBase} from "../abstractComponent/element-base";

@Component({
  selector: 'pd-form-checkbox',
  template: require('./form-checkbox.component.html'),
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: FormCheckboxComponent, multi: true}
  ]
})
export class FormCheckboxComponent extends ElementBase<boolean> {
  constructor(public formDirective: NgForm) {
    super(formDirective);
  }
}
