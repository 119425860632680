import {Component, ViewEncapsulation, Renderer2, Input, ViewChild, ElementRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, Validator} from "@angular/forms";
import * as _ from "lodash";
import {AbstractControl} from "@angular/forms/src/model";
import {ElementBase} from "baza/app/framework/components/form/abstractComponent/element-base";
import {LongTimeFormatter} from "baza/app/framework/components/form/form-time-spent/long-time-formatter";

@Component({
  selector: 'pd-form-time-spent',
  template: require('./form-time-spent.component.html'),
  styles: [require('./form-time-spent.component.scss')],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: FormTimeSpentComponent, multi: true},
    {provide: NG_VALIDATORS, useExisting: FormTimeSpentComponent, multi: true}
  ]
})
export class FormTimeSpentComponent extends ElementBase<any> implements Validator {

  @Input()
  public disabled: boolean = false;
  @Input()
  public required: boolean = false;
  @Input()
  public invalid: boolean = false;

  public maskObject: Object;

  @ViewChild('time') time: ElementRef;

  protected static DELETE_CODE = 46;
  protected static BACKSPACE_CODE = 8;
  protected static ARROW_UP_CODE = 38;
  protected static ARROW_DOWN_CODE = 40;
  protected static TIME_STEP = 5;

  protected formatter: LongTimeFormatter = new LongTimeFormatter();
  protected formattedValueForView: any = null;

  protected canSetTime: boolean = false;

  constructor(public formDirective: NgForm, protected renderer: Renderer2) {
    super(formDirective);
  }

  ngOnInit() {
    this.maskObject = {
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
      guide: true,
      keepCharPositions: false
    }
  }

  writeValue(value: number) {
    super.writeValue(value);
    this.formattedValueForView = this.formatter.formatValue(value);
  }

  checkFieldValue() {
    this.canSetTime = true;
  }

  checkValue() {
    if(!this.canSetTime || _.isNull(this.formattedValueForView) || !_.isNull((this.formattedValueForView.match(/[0-9][0-9][0-9]:[0-9][0-9]/)))) {
      return;
    }


  }

  updateValue() {
    this.value = this.formatter.toNumber(this.formattedValueForView);
  }

  validate(c: AbstractControl): any {
    return null;
  };


}


