import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sumAttribute' })
export class SumAttributePipe implements PipeTransform {
  transform(values, field: string): any {
    if(!values || values.length === 0) {
      return null;
    }

    return values.map((value) => value[field]).reduce((prev, current) => {
      return prev + current;
    });
  }
}
