import * as _ from "lodash";


export class MoneyFormatter {
  static readonly NUMBER_SEPARATOR = ' ';


  formatValue(value: number | string) :string{
    if (!value && value !== 0) {
      return ''
    }
    const valueAsString = `${value}`;

    const [digits, fraction] = this.splitByFractionSeparator(valueAsString);
    const formattedValue = this.serializeNumberToString(digits, fraction);
    return formattedValue;
  }

  parseFormattedValue(value: number | string): number {
    return parseFloat(this.removeWhiteCharacters(value).replace(/[,]/, '.'));
  }

  isValid(formattedValue: string) {
    let valueWithoutWhiteSpace = this.removeWhiteCharacters(formattedValue);
    return !!valueWithoutWhiteSpace.match(/^(-?)(\d+)([.,]\d+)?$/g);
  }

  private removeWhiteCharacters(value: number | string): string {
    const valueAsString = `${value}`;
    return valueAsString.replace(/\s+/g, '');
  }

  private serializeNumberToString(digits: string, fraction: string) {

    let digitsTabs = digits.split(/\s*/).reverse();
    let formattedValue = "";
    let counter = 0;

    _.forEach(digitsTabs, (character: string) => {
      if (counter === 3) {
        counter = 0;
        formattedValue = `${MoneyFormatter.NUMBER_SEPARATOR}${formattedValue}`
      }
      formattedValue = `${character}${formattedValue}`;
      counter++;
    });

    if (_.isEmpty(fraction)) {
      return `${formattedValue}`;
    }

    return `${formattedValue},${fraction}`;
  }


  private splitByFractionSeparator(valueAsString: string) {
    let splitNumber: string[] = this.removeWhiteCharacters(valueAsString).split(/[.,]+/);

    let fraction: string = "";
    if (splitNumber.length === 2) {
      fraction = this.round(splitNumber[1]);
    }
    return [splitNumber[0], fraction];
  }

  private round(fraction: string) :string {
    let fixed: string = Number('0.' + fraction).toFixed(2);
    let splitNumber: string[] = fixed.split(/[.,]+/);
    return splitNumber[1];
  }
}