import {AbstractConverter} from "./AbstractConverter";
import {DateConverter} from "./DateConverter";
import {Metadata} from "../../../reflection/Metadata";
import {TimeConverter} from "./TimeConverter";

export function TypeDate(target: any, attributeName: string) {
  if (!Metadata.isMetaSet(target, 'converters')) {
    Metadata.setMeta(target, 'converters', {});
  }
  const serializer: {[key in string] : AbstractConverter<any, any>} = Metadata.getMeta(target, 'converters');
  serializer[attributeName] = DateConverter.formatForJson();
}

export function TypeTime(target: any, attributeName: string) {
  if (!Metadata.isMetaSet(target, 'converters')) {
    Metadata.setMeta(target, 'converters', {});
  }
  const serializer: {[key in string] : AbstractConverter<any, any>} = Metadata.getMeta(target, 'converters');
  serializer[attributeName] = TimeConverter.formatForJson();
}
