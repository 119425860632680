import {EventEmitter, Injectable} from "@angular/core";
import {NetworkJsonApiService} from "../jsonapi/network-jsonapi.service";
import * as _ from "lodash";
import {Subscription} from "rxjs";
import {AccessRightModel} from "../../../transferObject";


@Injectable()
export class AccessRightService {

  private permissionUpdated: EventEmitter<void> = new EventEmitter<void>();
  private permissionLoaded: boolean = false;

  constructor(private networkService: NetworkJsonApiService) {
  }

  private accessRightsCache: AccessRightModel[] = [];

  reloadPermissions() {
    this.networkService.getResources('/api/access_rights').subscribe((accessRight: AccessRightModel[]) => {
      this.accessRightsCache = accessRight;
      this.permissionLoaded = true;
      this.permissionUpdated.emit();
    })
  }

  hasRight(rightName: string)  : boolean{
    return!!_.find(this.accessRightsCache, {right : rightName})
  }

  loaded(): boolean {
    return this.permissionLoaded;
  }

  clearRightsCache() {
    this.accessRightsCache = [];
    this.permissionUpdated.emit();
  }


  public setAccessRightUpdateListener(callback: () => void): Subscription {
    return this.permissionUpdated.subscribe(() => callback());
  }

}