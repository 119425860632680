import {Component, OnInit} from '@angular/core';
import {NetworkJsonApiService} from "../../../../services/jsonapi/network-jsonapi.service";
import {AccountModel} from "../../../../../transferObject/index";
import {Observable} from "rxjs";

@Component({
  selector: 'pd-model-list-attribute-sample',
  template: require('./model-list-attribute-sample.component.html'),
  styles: [require('./model-list-attribute-sample.component.less')]
})
export class ModelListAttributeSampleComponent implements OnInit {

  protected model: Observable<AccountModel>;

  constructor(private networkService: NetworkJsonApiService) {
  }

  ngOnInit() {

    this.model = this.networkService.getResource('/api/accounts/1');
  }
}
