import '../../../transferObject/index';
import {Injectable} from '@angular/core';
import {Converter, DefaultFromJsonApiConverter, DefaultToJsonApiConverter, ErrorConverter} from "./converters/common";
import {JsonApiData, JsonApiResource, JsonApiScalarWrapper} from "./jsonapi";
import {Typed} from "./common";
import {DefaultFromJsonErrorConverter} from "./converters/default-from-json-error-converter";

@Injectable()
export class ConversionService {
  requestConverters: Converter<any, any>[] = [];
  responseConverters: Converter<any, any>[] = [];
  responseErrorConverters: ErrorConverter<any>[] = [];

  defaultRequestConverter: Converter<any, JsonApiResource>;
  defaultResponseConverter: Converter<JsonApiScalarWrapper<JsonApiData>, any>;
  defaultResponseErrorConverter: ErrorConverter<any> = new DefaultFromJsonErrorConverter();

  constructor() {
    this.defaultRequestConverter = new DefaultToJsonApiConverter(this);
    this.defaultResponseConverter = new DefaultFromJsonApiConverter(this);
    //custom converter Custom converter this.responseConverters.push(new AddressConverter(this));
  }


  convertRequest<T>(arg: Typed, cache = [], include = []): JsonApiResource {
    for (const converter of this.requestConverters) {
      if (converter.canConvert(arg)) {
        return converter.convert(arg, cache, include);
      }
    }
    return this.defaultRequestConverter.convert(arg, cache, include);
  }

  convertResponse<T>(arg: any, cache = []): T {
    for (const converter of this.responseConverters) {
      if (converter.canConvert(arg)) {
        return <T>converter.convert(arg, cache, []);
      }
    }
    return <T>this.defaultResponseConverter.convert(arg, cache, []);
  }


  convertError<T>(error: any): T {
    for (const converter of this.responseErrorConverters) {
      if (converter.canConvert(error)) {
        return converter.convert(error);
      }
    }
    return this.defaultResponseErrorConverter.convert(error);
  }
}
