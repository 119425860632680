import {Model} from '../framework/services/jsonapi/common';
import {AbstractResourceModel} from "./common/abstract-resource-model";

import {

} from "./";
import "reflect-metadata";
import {TypeDate} from "../framework/services/jsonapi/converters/attributes/TypeDate";

@Model('accounts')
export class AccountModel extends AbstractResourceModel {

  public firstName: string = null;
  public lastName: string = null;
  public email: string = null;
  public productivity: boolean = null;
  @TypeDate
  public workStartOnOverride: Date = null;
  public imagePath: string = null;
  public billability: number = null;
  public position: string = null;
  @TypeDate
  public careerExperience: Date = null;
  @TypeDate
  public projectExperience: Date = null;
  @TypeDate
  public companyExperience: Date = null;
  public activityMonitoring: boolean = null;
  // public role: RoleModel = null;
  @TypeDate
  public careerStartOn: Date = null;
  public active: boolean = null;
  public awesomeness: number = null;
  public margin: string = null;
  public password: string = null;
  public passwordConfirmation: string = null;
  public currentPassword: string = null;
  public canLogin: boolean = null;
  @TypeDate
  public bhpDate: Date = null;
  @TypeDate
  public checksDate: Date = null;
  public appSupport: boolean = null;
  public phone: string = null;
  public address: string = null;
  public educationType: string = null;
  public education: string = null;

  constructor() {
    super('/api/accounts');
  }

  static createNew(): AccountModel {
    let account = new AccountModel();
    account.productivity = false;
    account.activityMonitoring = false;
    account.awesomeness = 3;
    account.canLogin = false;
    return account;
  }

  toString(): string{
    return `${this.firstName} ${this.lastName}`;
  }

}
