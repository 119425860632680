import {IdType, JsonApiData, JsonApiResource, JsonApiScalarWrapper, Links, Relationships} from '../jsonapi';
import {ConversionService} from '../conversion.service';
import {Typed, typesMap} from '../common';
import * as _ from 'lodash';
import {AbstractConverter} from "./attributes/AbstractConverter";
import {Metadata} from "../../reflection/Metadata";


export interface Converter<S, T> {
  convert(source: S, cache: {}[], include: string[]): T;

  canConvert(source: S): boolean;
}

export interface ErrorConverter<T> {
  convert(error: any): T;

  canConvert(source: any): boolean;
}

/*
*   Default converters convert **single** model <-> **single** JsonApi resource.
*   Merging of multiple resources into one final JsonApiResource happens in NetworkJsonApiService (same for splitting).
*
*   Every property of model which is decorated with @Model is considered to be a relationship.
*   Name of such property should match the name of relationship.
*   If response does not contain object referenced by relationship in 'included', no conversion happens.
*   Other model properties are considered to be attributes.
*
*/
export class DefaultFromJsonApiConverter implements Converter<JsonApiScalarWrapper<JsonApiData>, any> {

  constructor(protected conversionService: ConversionService) {

  }

  convert(source: JsonApiScalarWrapper<JsonApiData>, cache: {}[]): any {
    const data = source.getData();
    if(_.isEmpty(data)) {
      return;
    }

    const target = new typesMap[source.getType()];

    const cached = _.find(cache, item => item['id'] === data.id && (<Typed>item).getType() === data.type);
    if (cached) {
      return cached;
    }

    this.populateAttributes(target, source);
    cache.push(target);
    this.populateLinks(target, data.links);
    this.populateRelationships(target, source, cache);
    target.meta = data.meta;
    return target;
  }

  canConvert(source: Typed): boolean {
    return true;
  }

  private populateLinks(target: any, source: Links) {
    if (source) {
      const sourceLinkNames = Object.getOwnPropertyNames(source).map(toCamelCase);
      const targetLinks = target['links'];
      if (targetLinks !== undefined && targetLinks !== null) {
        const targetLinkNames = Object.getOwnPropertyNames(targetLinks);
        const commonNames = _.intersection(sourceLinkNames, targetLinkNames);
        for (const linkName of commonNames) {
          targetLinks[linkName] = source[toSnakeCase(linkName)];
        }
      }
    }
  }

  private populateAttributes(target: any, source: JsonApiScalarWrapper<JsonApiData>) {
    const data = source.getData();
    const targetProperties = Object.getOwnPropertyNames(target);
    const sourceAttributes = Object.getOwnPropertyNames(data.attributes).map(toCamelCase);
    if (targetProperties.indexOf('id') > -1) {
      target['id'] = data.id;
    }
    const commonAttributes = _.intersection(targetProperties, sourceAttributes);

    for (const attribute of commonAttributes) {
      target[attribute] = data.attributes[toSnakeCase(attribute)];
      target[attribute] = this.deserializeAttribute(target, attribute);
    }
  }

  private deserializeAttribute(target: any, attribute: string) {
    const attributeConverters: {[key in string ] :AbstractConverter<any, any>} = Metadata.getMeta(target,"converters",{});
    if (attributeConverters && attributeConverters[attribute]) {
      const converter : AbstractConverter<any,any> = attributeConverters[attribute];
      return converter.convertToType(target[attribute]);
    }
    return target[attribute];
  }


  private populateRelationships(target: any, source: JsonApiScalarWrapper<JsonApiData>, cache: {}[]) {
    const data = source.getData();
    const targetProperties = Object.getOwnPropertyNames(target);

    if (data.relationships) {
      const sourceRelations = Object.getOwnPropertyNames(data.relationships).map(toCamelCase);
      const commonRelations = _.intersection(targetProperties, sourceRelations);

      for (const relationName of commonRelations) {
        const relation = data.relationships[toSnakeCase(relationName)];
        const related = source.getRelated(toSnakeCase(relationName));
        const models = _.map(related, (relData) => this.conversionService.convertResponse(source.toWrapper(relData), cache));
        if (relation.data instanceof Array) {
          target[relationName] = models;
        } else {
          if (models.length === 1) {
            target[relationName] = models[0];
          } else {
            target[relationName] = null;
          }
        }
      }
    }
  }
}

export class DefaultToJsonApiConverter implements Converter<any, JsonApiResource> {

  constructor(private conversionService: ConversionService) {

  }

  convert(source: any, cache: {}[], include: string[]): JsonApiResource {
      const primaryData = {} as JsonApiData;
      primaryData.id = source.id;
      primaryData.type = source.getType();
      const cached = _.find(cache, item => item['data']['id'] === primaryData.id && item['data']['type'] === primaryData.type);
      if (cached) {
        return <JsonApiResource>cached;
      }
      primaryData.attributes = this.getAttributes(source);
      const target = new JsonApiResource(primaryData);
      cache.push(target);

      const relationshipsIncluded = this.getRelationships(source, cache, include);
      primaryData.relationships = relationshipsIncluded[0];
      target.included = _.uniqBy(relationshipsIncluded[1], (inc) => inc.id + inc.type);
      return target;
  }

  canConvert(source: any): boolean {
    return true;
  }

  private serializeAttribute(source: any, prop: string) {
    const converters: {[key in string ] :AbstractConverter<any, any>} = Metadata.getMeta(source,"converters", {});
    if (converters && converters[prop]) {
      let converter: AbstractConverter<any, any> = converters[prop];
      return converter.formatValue(source[prop]);
    }
    return source[prop];
  }

  private isAttribute(val: any): boolean {
    const match = (type => type === 'number' || type === 'string' || type === 'boolean');

    if (val instanceof Array) {
      return _.chain(val).map(v => typeof v).every(match).value();
    }

    if (val instanceof Date) {
      return true;
    }

    return match(typeof val);
  }

  private getRelationships(source: any, cache: {}[], include: string[]): [Relationships, JsonApiData[]] {
    const properties = Object.getOwnPropertyNames(source);
    const propertiesToInclude = _.intersection(properties, include);
    const relationships = {};
    const included = [];

    for (const prop of propertiesToInclude) {
      const val: any = source[prop];

      // do not include non transfer objects
      if(typeof val.getType !== 'function' && !(val instanceof Array)) {
        continue;
      }

      const relationship = {};
      const includeNested = _.chain(include)
        .filter(rel => rel.startsWith(prop))
        .map(rel => rel.substr(prop.length + 1)).value();

      if (val instanceof Array) {
        const data = _.chain(val).filter(v => v.getType).map(this.toRelationship).value();
        const includedRel = _.chain(val).filter(v => v.getType).map(v => this.flattenToIncluded(v, cache, includeNested)).value();
        relationship[toSnakeCase(prop)] = {data: data};
        included.push(_.flatten(includedRel));

      } else if (val && (<Typed>val).getType()) {
        const data = this.toRelationship(val);
        relationship[toSnakeCase(prop)] = {data: data};
        included.push(this.flattenToIncluded(<Typed>val, cache, includeNested));
      }
      Object.assign(relationships, relationship);
    }
    return [relationships, _.flatten(included)];
  }

  private flattenToIncluded(val: Typed, cache: {}[], include: ArrayLike<string>): JsonApiData[] {
    const relationResource = this.conversionService.convertRequest(val, cache, <string[]>include); //TEST
    const included = relationResource.included || [];
    included.push(<JsonApiData>relationResource.data);

    return included;
  }

  private toRelationship(val: any): IdType {
    return {
      id: '' + val.id,
      type: val.getType()
    };

  }

  private getAttributes(source: any): {} {
    const properties = Object.getOwnPropertyNames(source);
    const attributes = {};
    for (const prop of properties) {
      if (prop !== 'id' && this.isAttribute(source[prop])) {
        let attributeName = toSnakeCase(prop);
        attributes[attributeName] = this.serializeAttribute(source, prop)
      }
    }
    return attributes;
  }
}

function toSnakeCase(name: string): string {
  return name.replace(/[A-Z]/g, match => '_' + match.toLowerCase());
}

function toCamelCase(name: string): string {
  return name.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
}
