import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'pd-form-buttons',
  template: require('./form-buttons.component.html')
})
export class FormButtonsComponent implements OnInit {

  @Input()
  public declineMethod: () => void;


  ngOnInit() {
  }

}
