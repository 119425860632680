import {Pipe, PipeTransform} from '@angular/core';
import * as moment from "moment";

@Pipe({
  name: 'timeFormatter'
})

export class TimeFormatterPipe implements PipeTransform {

  transform(time: number, format: string): string {
    if(format === "HH:mm") {
      return this.hoursAndMinutesFormat(time);
    } else {
      return this.defaultFormat(time);
    }
  }

  private defaultFormat(time: number): string {
    let hours: number = ~~(time / 60);
    if(Math.abs(hours) > 0 ) {
      return `${hours}.${Math.abs(time % 60)} h`
    } else {
      return `${time} min`
    }
  }

  private hoursAndMinutesFormat(time: number): string {
    let hours: number = Math.abs(time) / 60;
    let minutes: number = Math.abs(time) % 60;
    let fullTime: string = moment(new Date(new Date().setHours(hours,minutes,0,0))).format("HH:mm");
    if (time < 0) {
      return `- ${fullTime}`;
    }
    return fullTime;
  }

}
