import {Directive, EventEmitter, HostListener} from '@angular/core';
import {ModalWindowService} from "../../../services/modalWindow/modal-window.service";
import {Subscription} from "rxjs";

@Directive({
  selector: '[pdModalWindowSaveButton]'
})
export class ModalWindowSaveButtonDirective {

  constructor(private modalWindowService: ModalWindowService) {
  }

  @HostListener('click')
  saveWithoutClose() {
    this.modalWindowService.saveWithoutClose();
  }

}
