import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'minDate' })
export class MinDatePipe implements PipeTransform {
  transform(values, field: string): any {
    if(!values) {
      return null;
    }

    return values.map((value) => value[field]).reduce((prev, current) => {
      return (prev < current) ? prev : current;
    });
  }
}