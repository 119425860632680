import {DateConverter} from "../../../services/jsonapi/converters/attributes/DateConverter";


export class DateFormatter {

  private dateConverter: DateConverter = DateConverter.formatForView();

  formatValue(value: Date): string {
    return this.dateConverter.formatValue(value);
  }

  parseFormattedValue(value: string): Date {
    if (value && this.dateConverter.isValidDate(value)) {
      return this.dateConverter.convertToType(value);
    }

    return null;
  }

  isValid(formattedValue: string) {
    return this.dateConverter.isValidDate(formattedValue);
  }
}