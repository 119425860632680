import {Pipe, PipeTransform} from '@angular/core';
import {MoneyFormatter} from "../form/form-money/money-formatter";

@Pipe({
  name: 'moneyFormatter'
})
export class MoneyFormatterPipe implements PipeTransform {

  private moneyFormatter = new MoneyFormatter();

  transform(value: any, args?: any): any {
    return this.moneyFormatter.formatValue(value);
  }

}
