import { Model } from '../framework/services/jsonapi/common';
import { AbstractResourceModel } from "./common/abstract-resource-model";
import {AccountModel} from "./AccountModel";

@Model('account_settings')
export class AccountSettingsModel extends AbstractResourceModel {

  public language: string = null;
  public location: string = null;
  public account: AccountModel = null;


  constructor() {
    super('/api/account_settings');
  }
}