import {Model} from '../framework/services/jsonapi/common';
import {AbstractResourceModel} from "./common/abstract-resource-model";
import {TypeDate} from "../framework/services/jsonapi/converters/attributes/TypeDate";
import {DateFormatterPipe} from "../framework/components/pipes/date-formatter.pipe";

@Model('settings')
export class SettingModel extends AbstractResourceModel {

  public name: string = null;
  public value: string = null;

  constructor() {
    super('/api/settings');
  }

  toString(): string{
    return `${this.name} - ${this.value}`;
  }
  
}
