import {ServerValidationError} from "../../../../transferObject/errors/ServerValidationError";
import * as _ from "lodash";
import {ErrorResponse} from "../../../../transferObject/errors/ErrorResponse";
import {ErrorConverter} from "./common";

export class DefaultFromJsonErrorConverter implements ErrorConverter<ErrorResponse> {

  canConvert(source: any): boolean {
    return true;
  }

  convert(error: any): ErrorResponse {
    const response: ErrorResponse = new ErrorResponse(error.status);
    if (error.status === 422) {
      response.attrValidationErrors = this.convertFormAttribute(error.error.errors);
    }
    return response;
  }

  private convertFormAttribute(formsError: any[]): ServerValidationError[] {
    return _.map(formsError, (error) => {
      const msg: string = error.detail;
      const attribute: string = error.source.pointer.replace('/data/attributes/', '');
      return new ServerValidationError(attribute, msg);
    });
  }
}