import {Component, ElementRef, Input, SimpleChanges, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR, NgForm} from "@angular/forms";
import {AbstractMultiOptionComponent} from "../abstractComponent/abstract-multi-option-component";
import {NetworkJsonApiService} from "../../../services/jsonapi/network-jsonapi.service";
import * as _ from "lodash";


@Component({
  host: {
    '(document:click)': 'clickOutsideMultiselect($event)',
  },
  selector: 'pd-form-multiselect',
  template: require('./form-multiselect.component.html'),
  styles: [require('./form-multiselect.component.scss')],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: FormMultiselectComponent, multi: true}]
})
export class FormMultiselectComponent extends AbstractMultiOptionComponent<any[]> {

  private static readonly ARROW_DOWN_KEY_CODE = 40;
  private static readonly ARROW_UP_KEY_CODE = 38;
  private static readonly ENTER_KEY_CODE = 13;
  private static readonly BACKSPACE_KEY_CODE = 8;
  private static readonly ESCAPE_KEY_CODE = 27;

  constructor(protected  ngForm: NgForm, private elementRef: ElementRef,
              protected  networkService: NetworkJsonApiService) {
    super(ngForm, networkService);
  }

  @ViewChild("multiInput") inputElement: ElementRef;

  @Input()
  public newOptionFactory: (label: string) => any = null;

  public searchedPhrase: string = '';
  public filteredOptions: any[] = [];

  public showOptions: boolean = false;
  public highlightedOptionIndex: number = 0;

  focusInput() {
    this.inputElement.nativeElement.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['options']) {
      return;
    }

    this.reloadOptionsList(changes['options'].currentValue).subscribe((options) => {
      this.innerOptionsList = options;
      this.filteredOptions = this.sortOptions(options);
    })
  }

  doSearchAction(event, phrase=null) {
    const character: number = event.keyCode;
    this.showOptions = true;

    if (character === FormMultiselectComponent.ENTER_KEY_CODE) {
      this.actionAfterPushingEnter(event);
    } else if (character === FormMultiselectComponent.ARROW_UP_KEY_CODE) {
      this.actionAfterPushingArrowUp();
    } else if (character === FormMultiselectComponent.ARROW_DOWN_KEY_CODE) {
      this.actionAfterPushingArrowDown();
    }  else if (character === FormMultiselectComponent.ESCAPE_KEY_CODE) {
      this.actionAfterPushingEscape();
    } else {
      this.actionAfterUpdatingPhrase();
    }
  }

  doSearchActionAfterPushingBackSpace(event) {
    const character: number = event.keyCode;
    if (character !== FormMultiselectComponent.BACKSPACE_KEY_CODE) {
      return
    }

    if (_.size(this.searchedPhrase) !== 0 || _.isEmpty(this.value)) {
      this.actionAfterUpdatingPhrase();
      return;
    }

    let item = this.value[this.value.length - 1];
    this.searchedPhrase = this.toLabel(item);
    this.deleteFromSelected(item, this.value.length - 1);
    this.actionAfterUpdatingPhrase();
  }

  private actionAfterUpdatingPhrase() {
    this.highlightedOptionIndex = 0;
    this.filteredOptions = _.filter(this.innerOptionsList, (option) => `${this.toLabel(option)}`.indexOf(this.searchedPhrase) !== -1);
    this.filteredOptions = _.differenceWith(this.filteredOptions, this.value, this.compareFn);
    this.filteredOptions = this.sortOptions(this.filteredOptions);
  }

  private actionAfterPushingEnter(event) {
    if (this.filteredOptions.length !== 0) {
      const item = this.filteredOptions[this.highlightedOptionIndex];
      this.selectedOption(item, this.highlightedOptionIndex)
    } else if (this.newOptionFactory && this.searchedPhrase.trim().length !== 0) {
      const newOption = this.newOptionFactory(this.searchedPhrase);
      this.filteredOptions.push(newOption);
      this.selectedOption(newOption, this.filteredOptions.length - 1);
      this.resetSearch();
    }
  }

  private resetSearch() {
    this.searchedPhrase = '';
    this.actionAfterUpdatingPhrase();
  }

  private actionAfterPushingArrowUp() {
    this.highlightedOptionIndex--;

    if (this.filteredOptions.length === 0) {
      this.highlightedOptionIndex = 0;
    }

    if (this.highlightedOptionIndex < 0) {
      this.highlightedOptionIndex = 0;
    }
  }

  private actionAfterPushingArrowDown() {
    this.highlightedOptionIndex++;

    if (this.filteredOptions.length === 0) {
      this.highlightedOptionIndex = 0;
    }

    if (this.highlightedOptionIndex >= this.filteredOptions.length) {
      this.highlightedOptionIndex = this.filteredOptions.length - 1;
    }
  }

  public selectedOptionByClick(option, index) {
    this.selectedOption(option, index)
  }

  public deleteFromSelected(item: any, index: number) {
    this.value.splice(index, 1);
    this.markAsDirty();

    this.filteredOptions.push(item);
    this.actionAfterUpdatingPhrase();
  }

  private sortOptions(options: any) {
    return _.sortBy(options, [(option: any) => this.toLabel(option)]);
  }

  private selectedOption(option, index) {
    if (!this.value) {
      this.setValue([]);
    }
    this.value.push(option);
    this.markAsDirty();

    this.filteredOptions.splice(index, 1);
    this.highlightedOptionIndex = 0;
    this.searchedPhrase = '';
    this.showOptions = false;
  }

  public displayOptions() {
    this.highlightedOptionIndex = 0;
    this.showOptions = true;
  }

  public hideOptions() {
    this.showOptions = false;
    this.highlightedOptionIndex = 0;
  }

  private clickOutsideMultiselect($event) {
    if (!this.elementRef.nativeElement.contains($event.target)) {
      this.hideOptions();
    }
  }

  private actionAfterPushingEscape() {
    this.hideOptions();
  }
}