import {AbstractResourceLifecycleListener} from "./abstract-resource-lifecycle-listener";

export abstract class MockResourceLifecycleListener<MODEL> implements AbstractResourceLifecycleListener<MODEL> {

  public beforeLoadingModelFromServer(url) {
  }

  public afterLoadingModelFromServerWithSuccess(model: MODEL) {
  }

  public afterLoadingModelFromServerWithError(error: any) {
  }

  public beforeFillingForm(model: MODEL) {
  }

  public afterFillingForm(model: MODEL) {
  }

  public beforeFormValidation() {
  }

  public afterValidationFinishedWithSuccess() {
  }

  public afterValidationFinishedWithError(error: any) {
  }

  public beforeSavingModelOnServer(model: MODEL) {
  }


  public afterSavingModelOnServerWithSuccess(model: MODEL) {
  }

  public afterSavingModelOnServerWithError(error: any) {
  }


}