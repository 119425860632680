import {
  ComponentFactory,
  ComponentFactoryResolver,
  EventEmitter,
  Injectable,
  Output,
  ViewContainerRef
} from '@angular/core';
import {ModalWindowComponent} from "../../framework/components/modal-window/modal-window.component";
import {ComponentRef} from "@angular/core/src/linker/component_factory";
import {AbstractResourceEditor} from "../../framework/components/form/abstract-resource-editor/abstract-resouce-editor";
import {ResourceEditorLifecycleListener} from "./resource-form-lifecycle-listener";
import {ConfirmationMsgComponent} from "../../framework/components/modal-window/confirmation-msg/confirmation-msg.component";


@Injectable()
export class ModalWindowService {

  private mainContainer: ViewContainerRef;
  @Output()
  modalWasClosedByClick: EventEmitter<boolean> = new EventEmitter;
  @Output()
  saveWithoutCloseClick: EventEmitter<boolean> = new EventEmitter;

  constructor(private componentResolver: ComponentFactoryResolver) {
  }

  showModal(componentRef: ComponentRef<any>, withAdditionalSaveButton: boolean = false) {
    this.hideModal();

    const modalComponentRef: ComponentRef<ModalWindowComponent> = this.createModalComponent();
    modalComponentRef.instance.insertComponent(componentRef.hostView);
     modalComponentRef.instance.displaySaveButton = withAdditionalSaveButton;
  }

  showModalWithEditor(componentRef: ComponentRef<any>) {
    this.showModal(componentRef);
    this.closeModalAfterSubmittingForm(componentRef);
  }

  showModalWithEditorWithAdditionalSaveButton(componentRef: ComponentRef<any>) {
    this.showModal(componentRef, true);
    this.closeModalAfterSubmittingForm(componentRef);
  }

  showModalWithConfirmationMsg<T>(data?: T, title?: string, msg?: string, msgArgs?: object): ConfirmationMsgComponent {
    this.hideModal();

    const modalFactory: ComponentFactory<ConfirmationMsgComponent> = this.componentResolver.resolveComponentFactory(ConfirmationMsgComponent);
    const msgComponentRef: ComponentRef<ConfirmationMsgComponent> = this.mainContainer.createComponent(modalFactory);
    msgComponentRef.instance.setComponentProperties(data, title, msg, msgArgs);
    this.mainContainer.insert(msgComponentRef.hostView);

    return msgComponentRef.instance;
  }

  hideModal() {
    this.mainContainer.clear();
  }

  hideModalWithClick() {
    this.mainContainer.clear();
    this.modalWasClosedByClick.emit(true);
  }

  saveWithoutClose() {
    this.saveWithoutCloseClick.emit(true);
  }

  initModalComponent(modalWindow: ViewContainerRef) {
    this.mainContainer = modalWindow;
  }

  private closeModalAfterSubmittingForm(componentRef: ComponentRef<AbstractResourceEditor<any>>) {
    const editorLifecycleListener = this.createEditorLifecycleListener(componentRef);
    componentRef.instance.registerListener(editorLifecycleListener);
  }


  private createModalComponent(): ComponentRef<ModalWindowComponent> {
    let modalFactory: ComponentFactory<ModalWindowComponent> = this.componentResolver.resolveComponentFactory(ModalWindowComponent);
    return this.mainContainer.createComponent(modalFactory);
  }


  private createEditorLifecycleListener(component: ComponentRef<AbstractResourceEditor<any>>): ResourceEditorLifecycleListener {
    const editorListener = new ResourceEditorLifecycleListener(() => {
      this.hideModal();
      component.instance.unregisterListener(editorListener);
    });

    return editorListener;
  }

}
