import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {validateIfNotNull} from "../../common/Validators";
import {Subscription} from "rxjs";
import {AccessRightService} from "../../services/resource/access-right.service";

@Component({
  selector: 'pd-access-right',
  template: require('./access-right.component.html'),
  styles: [require('./access-right.component.scss')]
})
export class AccessRightComponent implements OnInit, OnDestroy {

  @Input()
  public right: string;

  public contentVisible: boolean = false;
  private permissionUpdateListener: Subscription = null;

  constructor(private accessRight: AccessRightService) {
  }

  ngOnInit() {
    validateIfNotNull(this.right, 'actionType', `Missing argument "actionType" for component ${AccessRightComponent.name}`);
    this.permissionUpdateListener = this.accessRight.setAccessRightUpdateListener(() => {
      this.setContentVisibility();
    });
    this.setContentVisibility();
  }

  setContentVisibility() {
    this.contentVisible = this.accessRight.hasRight(this.right);
  }

  ngOnDestroy(): void {
    if (this.permissionUpdateListener) {
      this.permissionUpdateListener.unsubscribe();
    }
  }
}
