import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ConversionService} from './framework/services/jsonapi/conversion.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ModalWindowComponent} from './framework/components/modal-window/modal-window.component';
import {ModalWindowService} from './services/modalWindow/modal-window.service';
import {FormHeaderComponent} from './framework/components/form/form-header/form-header.component';
import {TokensComponent} from './components/tokens/tokens.component';
import {TokenInterceptor} from './services/interceptors/token.interceptor';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {appRoutes} from './app.routes';
import {FormInputComponent} from "./framework/components/form/form-input/form-input.component";
import {FormCheckboxComponent} from "./framework/components/form/form-checkbox/form-checkbox.component";
import {FormValidationMsgComponent} from "./framework/components/form/form-validation-msg/form-validation-msg.component";
import {FormButtonsComponent} from "./framework/components/form/form-buttons/form-buttons.component";
import {FormSelectComponent} from "./framework/components/form/form-select/form-select.component";
import {FormRadioComponent} from "./framework/components/form/form-radio/form-radio.component";
import {FormMultiselectComponent} from "./framework/components/form/form-multiselect/form-multiselect.component";
import {NetworkJsonApiService} from "./framework/services/jsonapi/network-jsonapi.service";
import {ModelAttributesListComponent} from './framework/components/model-attributes-list/model-attributes-list.component';
import {ModelListAttributeSampleComponent} from './framework/components/model-attributes-list/sample/model-list-attribute-sample/model-list-attribute-sample.component';
import {TableComponent} from './framework/components/table/table.component';
import {TableColumnComponent} from './framework/components/table/table-column/table-column.component';
import {TableActionComponent} from './framework/components/table/table-action/table-action.component';
import {RenderTemplateDirective} from "./framework/directives/render_template.directive";
import {IfHasPermissionDirective} from "./framework/directives/if_has_permission.directive";
import {IterateDirective} from "./framework/directives/iterate.directive";
import {AttributeValueFormatterPipe} from "./framework/directives/attribute-value-formatter.pipe";
import {FormValidatorDirective} from "./framework/directives/form-validator.directive";
import {PdFormAsyncValidatorDirective} from "./framework/directives/form-async-validator.directive";
import {NavbarComponent} from "./components/navbar/navbar.component";
import {ModalWindowCloseButtonDirective} from './framework/components/modal-window/modal-window-close-button.directive';
import {ConfirmationMsgComponent} from './framework/components/modal-window/confirmation-msg/confirmation-msg.component';
import {CustomComponentFactory} from "./framework/components/form/abstract-resource-editor/custom_component_factory";
import {DropzoneModule} from "ngx-dropzone-wrapper";
import {FormTextareaComponent} from "./framework/components/form/form-textarea/form-textarea.component";
import {FormDateComponent} from "./framework/components/form/form-date/form-date.component";
import {FormTimeComponent} from "./framework/components/form/form-time/form-time.component";
import {AccessRightComponent} from './framework/components/access-right/access-right.component';
import {AccessRightService} from "./framework/services/resource/access-right.service";
import {FormMoneyComponent} from './framework/components/form/form-money/form-money.component';
import {MoneyFormatterPipe} from './framework/components/pipes/money-formatter.pipe';
import {DecimalPipe} from "@angular/common";
import {DateFormatterPipe} from "./framework/components/pipes/date-formatter.pipe";
import {FilterPipe} from './framework/components/pipes/filter.pipe';
import {LoginPageComponent} from "./pages/login-page/login-page.component";
import {OnlyLoggedUsersGuard} from "./guards/only-logged-users.guard";
import {AuthService} from "./services/auth.service";
import {NavbarService} from "./services/navbar.service";
import {SortPipe} from './framework/components/pipes/sort.pipe';
import {TimeDiffPipe} from './framework/components/pipes/time-diff.pipe';
import {DashboardPageComponent} from "./pages/dashboard-page/dashboard-page.component";
import {TimeFormatterPipe} from "./framework/components/pipes/time-formatter.pipe";
import {TextMaskModule} from 'angular2-text-mask';
import {CamelCasePipe} from './framework/components/pipes/camel-case.pipe';
import {OnlyManagementGuard} from "./guards/only-management.guard";
import {LOCALE_ID} from "@angular/core";
import {registerLocaleData} from "@angular/common";
import localePl from '@angular/common/locales/pl';
import {ClickOutsideModule} from "ng4-click-outside";
import {LocalizedDatePipe} from "./framework/components/pipes/localized-date.pipe";
import {AccountRoleService} from "./services/account-role.service";
import {AccountSettingsService} from "./services/account-settings.service";
import {TooltipComponent} from './framework/components/tooltip/tooltip.component';
import {NgDatepickerModule} from 'ng2-datepicker';
import {GroupByPipe} from "./framework/components/pipes/groupBy.pipe";
import {MinDatePipe} from "./framework/components/pipes/min-date.pipe";
import {MaxDatePipe} from "./framework/components/pipes/max-date.pipe";
import {SumAttributePipe} from "./framework/components/pipes/sum-attribute.pipe";
import {RedirectInterceptor} from "./services/interceptors/redirect.interceptor";
import {NgProgressModule} from '@ngx-progressbar/core';
import {NgProgressHttpModule} from '@ngx-progressbar/http';
import {GroupPipe} from "./framework/components/pipes/group.pipe";
import {AccessRightGuard} from "./guards/access-right.guard";
import {Nl2BrPipeModule} from 'nl2br-pipe';
import {enableProdMode} from '@angular/core';
import {ActionButtonComponent} from "baza/app/components/action-button/action-button.component";
import {FormTimeSpentComponent} from "baza/app/framework/components/form/form-time-spent/form-time-spent.component";
import {LongTimeFormatterPipe} from "baza/app/framework/components/pipes/long-time-formatter.pipe";
import {GroupByWithIndexPipe} from "baza/app/framework/components/pipes/groupByWithIndex.pipe";
import {ModalWindowSaveButtonDirective} from "baza/app/framework/components/modal-window/modal-window-save-button.directive";
import {MainSearchComponent} from "baza/app/components/main-search/main-search.component";
import {OfferPageComponent} from "baza/app/pages/offer-page/offer-page.component";
import { StaticContentComponent } from './components/static-content/static-content.component';

registerLocaleData(localePl);

export const translationLoaderConfig
  = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
};


if (process.env.RAILS_ENV === 'production') {
  enableProdMode();
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
    FormInputComponent,
    ModalWindowComponent,
    FormCheckboxComponent,
    FormValidationMsgComponent,
    FormHeaderComponent,
    FormButtonsComponent,
    FormValidatorDirective,
    PdFormAsyncValidatorDirective,
    FormSelectComponent,
    TokensComponent,
    FormRadioComponent,
    FormMultiselectComponent,
    NavbarComponent,
    ModelAttributesListComponent,
    ModelListAttributeSampleComponent,
    TableComponent,
    TableColumnComponent,
    TableActionComponent,
    RenderTemplateDirective,
    IfHasPermissionDirective,
    IterateDirective,
    AttributeValueFormatterPipe,
    ModalWindowCloseButtonDirective,
    ModalWindowSaveButtonDirective,
    ConfirmationMsgComponent,
    FormTextareaComponent,
    AccessRightComponent,
    FormDateComponent,
    FormMoneyComponent,
    MoneyFormatterPipe,
    DateFormatterPipe,
    FilterPipe,
    LoginPageComponent,
    SortPipe,
    TimeDiffPipe,
    FormTimeComponent,
    FormTimeSpentComponent,
    DashboardPageComponent,
    OfferPageComponent,
    TimeFormatterPipe,
    CamelCasePipe,
    LocalizedDatePipe,
    TooltipComponent,
    GroupByPipe,
    GroupPipe,
    GroupByWithIndexPipe,
    MinDatePipe,
    MaxDatePipe,
    SumAttributePipe,
    ActionButtonComponent,
    LongTimeFormatterPipe,
    MainSearchComponent,
    StaticContentComponent,
  ],
  providers: [
    DecimalPipe,
    ConversionService,
    NetworkJsonApiService,
    AuthService,
    ModalWindowService,
    OnlyLoggedUsersGuard,
    OnlyManagementGuard,
    AccessRightGuard,
    AccessRightService,
    CustomComponentFactory,
    NavbarService,
    AccountRoleService,
    AccountSettingsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RedirectInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [AccountSettingsService],
      useFactory: (accountSettingsService) => accountSettingsService.getCurrentLanguage() || 'pl'
    },
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot(translationLoaderConfig),
    DropzoneModule,
    TextMaskModule,
    ClickOutsideModule,
    NgDatepickerModule,
    NgProgressModule,
    NgProgressHttpModule,
    Nl2BrPipeModule
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    TokensComponent,
    ModalWindowComponent,
    ConfirmationMsgComponent,
  ],
})
export class AppModule {
}
