import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(array: Array<any>, service: any, method: any): Array<any> {
    if(_.isEmpty(array)) {
      return [];
    }
    if(!service) {
      return array;
    }

    return service.sort(array, method);

  }

}
