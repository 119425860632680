import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, ContentChild, ContentChildren,
  EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, Type, ViewChild, ViewContainerRef,
} from '@angular/core';
import {TableColumnComponent} from "./table-column/table-column.component";
import {BehaviorSubject, isObservable, Observable, of} from "rxjs";
import {NetworkJsonApiService} from "../../services/jsonapi/network-jsonapi.service";
import {ArgumentTypes} from "../form/abstractComponent/argument-types";
import {TableActionComponent} from "./table-action/table-action.component";
import {getTranslationFor} from "../../common/ModelTranslation";
import {ModalWindowService} from "../../../services/modalWindow/modal-window.service";
import {CustomComponentFactory} from "../form/abstract-resource-editor/custom_component_factory";
import {AbstractResourceEditor} from "../form/abstract-resource-editor/abstract-resouce-editor";
import {TranslateService} from "@ngx-translate/core";
import * as _ from 'lodash';
import {TableGroupComponent} from "./table-group/table-group.component";
import {flatMap, filter} from "rxjs/operators";

@Component({
  selector: 'pd-table',
  template: require('./table.component.html'),
  styles: [require('./table.component.scss')],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnChanges, OnInit {

  @Output()
  public modelEdition: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public modelDeletion: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public modelUpdate: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public modelTypeName: string;

  @Input()
  public sortMethod: any;

  @Input()
  public sortService: any;

  @Input()
  public showHeader: boolean = true;

  @Input()
  public models: any[] | string;

  @Input()
  public sourceUrl: string;

  @Input()
  public groupBy: any;

  @ContentChildren(TableColumnComponent, {read: TableColumnComponent})
  private columns: QueryList<TableColumnComponent>;

  @ContentChild(TableActionComponent, {read: TableActionComponent})
  private actionColumn: TableActionComponent;

  @ContentChild(TableGroupComponent, {read: TableGroupComponent})
  private tableGroup: TableGroupComponent;

  @ViewChild("exampleContainer", {read: ViewContainerRef})
  private exampleContainer: ViewContainerRef;

  protected modelToDisplay: Observable<any> = null;
  protected groupedMap: Map<any, any> = null;
  protected sortSubject: BehaviorSubject<any> = null;

  constructor(private networkService: NetworkJsonApiService, private changeDetectorRef: ChangeDetectorRef,
              private modalService: ModalWindowService, private resourceEditorFactory: CustomComponentFactory,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    if(this.sortService) {
      this.initSort();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['models']) {
      this.refreshComponentData(<any[]>(changes['models'].currentValue));
    }
  }

  public getTranslation(column: TableColumnComponent): string {
    if (!this.modelTypeName) {
      throw new Error(`Missing attribute [name: 'modelTypeName']. The translation keys can not be generated. `);
    }
    return getTranslationFor(this.modelTypeName, column.attributeName);
  }

  trackById(index, value) {
    return value.id;
  }

  deleteModel(model: any) {
    let modelType: string = model.getType();
    this.translateService.get(`${_.camelCase(modelType)}ModelName`).subscribe((modelName: string) => {
      const confMsg = this.modalService.showModalWithConfirmationMsg(
        model, "", 'modalConfirmationMsgDelete', {modelName: modelName, modelAttributes: model.toString()});
      confMsg.confirmation.subscribe(() => {
        this.networkService.deleteResource(model.links.self.href).subscribe((model: any) => {
            this.downloadFreshModelsList();
            this.modelDeletion.next(model);
            this.modelUpdate.next(model);
          }
        );
      });
    })
  }

  editModel(model: any) {
    let editorComponentRef: ComponentRef<AbstractResourceEditor<any>> = this.resourceEditorFactory.createEditor(this.actionColumn.editorType, model);

    editorComponentRef.instance.save.subscribe(() => {
      this.downloadFreshModelsList();
      this.modelEdition.next(model);
      this.modelUpdate.next(model);
    });

    if(editorComponentRef.instance.saveWithoutClose) {
      this.modalService.showModalWithEditorWithAdditionalSaveButton(editorComponentRef);
    }
    else{
      this.modalService.showModalWithEditor(editorComponentRef);
    }

  }

  isArrayType(arg: any): boolean {
    return ArgumentTypes.isArray(arg);
  }

  private refreshComponentData(models: any[] | string) {
    of(models)
      .pipe(
        filter(models => !!models),
        flatMap((models: any[] | string) => this.downloadModelsIfNeccessary(models))
      )
      .subscribe(
        (models: any[]) => {
          this.modelToDisplay = isObservable(models) ? models : of(models);
          if (this.groupBy) {
            this.groupModels(models);
          }
          this.changeDetectorRef.markForCheck();
        },
        (error: any) => {
          throw new Error(error)
        });
  }

  private groupModels(models) {

    this.groupedMap = new Map();

    _.forEach(models, (model) => {
      let key = this.groupBy(this.groupedMap, model);
      if (this.groupedMap.has(key)) {
        let newList = this.groupedMap.get(key);
        newList.push(model);
        this.groupedMap.set(key, newList);
      } else {
        this.groupedMap.set(key, [model]);
      }
    });

  }

  private downloadModelsIfNeccessary(modelsOrString: any[] | string): Observable<any[]> {
    if (ArgumentTypes.isString(modelsOrString)) {
      return this.networkService.getResources(<string>modelsOrString);
    }
    return of(<any[]>modelsOrString);
  }

  private downloadFreshModelsList() {
    if (typeof this.models === 'string') {
      this.refreshComponentData(this.models);
    } else if (this.sourceUrl) {
      this.refreshComponentData(this.sourceUrl);
    }
  }

  initSort() {
    this.sortMethod = this.sortService.getDefaultSortMethod();
    this.sortSubject = new BehaviorSubject(this.sortMethod);
    this.sortSubject.subscribe(sortMethod => {
      if (this.sortMethod == sortMethod) {
        sortMethod = _.clone(sortMethod);
        sortMethod.reverse();
      }
      this.sortMethod = sortMethod;
    });
  }

  sortMethodExists(column): boolean {
    return this.sortService.getMethodByName(this.getAttributeNameFromColumn(column));
  }

  sortBy(column: TableColumnComponent) {
    this.sortSubject.next(this.sortService.getMethodByName(this.getAttributeNameFromColumn(column)));
  }

  getAttributeNameFromColumn(column: TableColumnComponent): string {
    return <string>column.attributeName;
  }
}



