import {Component, OnInit} from '@angular/core';


import {Subject} from "rxjs";
import {NetworkJsonApiService} from "baza/app/framework/services/jsonapi/network-jsonapi.service";
import {OfferModel} from "baza/app/transferObject";


@Component({
  selector: 'pd-dashboard-page',
  template: require('./dashboard-page.component.html'),
  styles: [require('./dashboard-page.component.scss')]
})
export class DashboardPageComponent implements OnInit {

  public destroyed = new Subject<any>();
  public offers: OfferModel[] = [];

  constructor(private networkService: NetworkJsonApiService) {

  }

  ngOnInit() {

    this.getOffers()

  }


  getOffers() {
    this.networkService.getResources('/api/offers').subscribe((offers: OfferModel[]) => {
      this.offers = offers;
    });
  }

}
