import * as _ from "lodash";

export class ArgumentTypes {

  public static isStringArray(value: any[]): boolean {
    return ArgumentTypes.isArrayAndItemIncludeAttribute(value, 'string');
  }

  public static isNumberArray(value: any[]): boolean {
    return ArgumentTypes.isArrayAndItemIncludeAttribute(value, 'number')
  }

  public static isString(value: any) {
    return typeof value === 'string';
  }

  public static isSimpleType(value: any) {
    const type = typeof value;
    return type === 'string' || type === 'boolean' || type === 'number';
  }

  public static isObject(item: any): boolean {
    return typeof item === 'object';
  }

  public static isArray(items: any) {
    return items instanceof Array;
  }

  public static isObjectArray(value: any) {
    return ArgumentTypes.isArrayAndItemIncludeAttribute(value, 'object');
  }

  public static isArrayAndItemIncludeAttribute(items: any, expectedType: string) {
    return items instanceof Array && !_.isEmpty(items) && typeof items[0] === expectedType;
  }

}