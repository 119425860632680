import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Observable, of} from "rxjs";
import * as _ from "lodash";
import {ArgumentTypes} from "../form/abstractComponent/argument-types";
import {NetworkJsonApiService} from "../../services/jsonapi/network-jsonapi.service";
import {filter, flatMap, map, tap, toArray} from "rxjs/operators";

type ModelAttributesListModelEntry = { key: string, value: any };
type ModelAttributesListConfigEntry = { [key in string]: string } | string;

@Component({
  selector: 'pd-model-attributes-list',
  template: require('./model-attributes-list.component.html'),
  styles: [require('./model-attributes-list.component.scss')],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelAttributesListComponent implements OnChanges {
  @Input()
  model: { [key in string]: string | Object | Array<any> } | string;

  @Input()
  visibleConfig: ModelAttributesListConfigEntry[];

  @Input()
  hiddenConfig: string[] = ['id', 'links'];

  @Input()
  labelPrefix: string = '';


  protected interiorList: Observable<ModelAttributesListModelEntry[]>;

  constructor(private networkService: NetworkJsonApiService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshAttributesList(this.model);
  }

  refreshAttributesList(model: any) {
    this.interiorList = of(model)
      .pipe(
        filter(model => model),
        flatMap(model => {
          if (!ArgumentTypes.isString(model)) {
            return of(model)
          }
          return this.networkService.getResource(model)
        }),
        map(model => this.asAttributeNameAndValueList(model)),
        flatMap(attribute => attribute),
        filter(attribute => this.shouldBeAttributeVisible(attribute)),
        tap(attribute => this.mapAttributeToString(attribute)),
        tap(attribute => this.addLabelPrefixToAtributeName(attribute)),
        toArray(),
        map((attributes) => this.sortAttributesBasedOnConfiguration(attributes, this.visibleConfig))
      );
  }

  isArrayType(value: any): boolean {
    return ArgumentTypes.isArray(value);
  }

  sortAttributesBasedOnConfiguration(attributes: ModelAttributesListModelEntry[], visibleConfig: ModelAttributesListConfigEntry[]) {
    if (!visibleConfig) {
      return attributes;
    }

    const sorted = [];

    _.forEach(attributes, (attribute: ModelAttributesListModelEntry) => {
      let order: number = _.findIndex(visibleConfig, (item) => item === attribute.key);
      if (order === -1) {
        order = _.findIndex(this.visibleConfig, attribute.key);
      }
      sorted[order] = attribute;
    });

    return sorted;
  }

  trackByIndex(index) {
    return index;
  }

  private asAttributeNameAndValueList(model: any): ModelAttributesListModelEntry[] {
    return _.map(model, (value: any, key: string) => {
      return {key: key, value: value}
    })
  }

  private shouldBeAttributeVisible(modelEntry: ModelAttributesListModelEntry) {
    const hideAttribute = _.find(this.hiddenConfig, (item: string) => item === modelEntry.key);
    if (hideAttribute) {
      return false
    }

    if (_.isEmpty(this.visibleConfig)) {
      return true;
    }

    return !!_.find(this.visibleConfig, (item) => {
      if (item === modelEntry.key) {
        return true;
      }

      return !!item[modelEntry.key]
    });
  }

  private mapAttributeToString(attribute: ModelAttributesListModelEntry) {

    if (ArgumentTypes.isArray(attribute.value)) {
      const config: ModelAttributesListConfigEntry = this.getConfigEntry(attribute);
      if (config) {
        attribute.value = _.map(attribute.value, (item) => item[config[attribute.key]]);
      }
    } else if (ArgumentTypes.isObject(attribute.value)) {
      const config: ModelAttributesListConfigEntry = this.getConfigEntry(attribute);
      if (config) {
        attribute.value = attribute.value[config[attribute.key]];
      }
    }
  }

  private getConfigEntry(attribute: ModelAttributesListModelEntry): ModelAttributesListConfigEntry {
    return _.find(this.visibleConfig, (configEntry: ModelAttributesListConfigEntry) => {
      if (ArgumentTypes.isObject(configEntry) && !!configEntry[attribute.key]) {
        return true;
      }
      return false
    });
  }

  private addLabelPrefixToAtributeName(attribute: ModelAttributesListModelEntry) {
    attribute.key + this.labelPrefix;
  }
}
