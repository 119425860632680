import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'pd-action-button',
  template: require('./action-button.component.html'),
  styles: [require('./action-button.component.scss')]
})
export class ActionButtonComponent implements OnInit {

  @Output() clickEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  emitClick() {
    this.clickEmitter.emit();
  }

}
