import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pd-static-content',
  template: require('./static-content.component.html'),
  styles: [require('./static-content.component.scss')]
})
export class StaticContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
