import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {AuthService} from "../services/auth.service";
import {Injectable} from "@angular/core";
import {NavbarService} from "../services/navbar.service";
import {map} from "rxjs/operators"

@Injectable()
export class OnlyLoggedUsersGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private navbarService: NavbarService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isLogendIn().pipe(map((result: boolean) => {
        if (result === false) {
          this.redirectToLogin(state.url)
        }
        this.navbarService.show();
        return result;
      })
    );
  }

  redirectToLogin(url: string) {
    this.router.navigate(['login'], {queryParams: {next: url}});
  }

}