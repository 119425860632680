import {Component, ElementRef, Input, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, Validator} from "@angular/forms";
import {AbstractControl} from "@angular/forms/src/model";
import {ElementBase} from "../abstractComponent/element-base";
import {DateFormatter} from "./date-formatter";
import {DatepickerOptions, NgDatepickerComponent} from "ng2-datepicker";
import {DateConverter} from "../../../services/jsonapi/converters/attributes/DateConverter";


@Component({
  selector: 'pd-form-date',
  template: require('./form-date.component.html'),
  styles: [require('./form-date.component.scss')],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: FormDateComponent, multi: true},
    {provide: NG_VALIDATORS, useExisting: FormDateComponent, multi: true}
  ]
})
export class FormDateComponent extends ElementBase<any> implements Validator {

  @Input()
  public disabled: boolean = false;

  @ViewChild('reference') datepicker: NgDatepickerComponent;

  options: DatepickerOptions = {
    displayFormat: DateConverter.VIEW_DATE_FORMAT,
    barTitleFormat: DateConverter.VIEW_DATE_FORMAT,
    dayNamesFormat: 'dd',
    firstCalendarDay: 1,
    barTitleIfEmpty: '',
    placeholder: this.identifier,
    addClass: 'form-control',
    addStyle: {},
    fieldId: this.identifier,
    useEmptyBarTitle: false
  };

  protected formatter: DateFormatter = new DateFormatter();
  protected formattedValueForView: any = null;

  constructor(public formDirective: NgForm) {
    super(formDirective);
  }

  onChange($event) {
    this.writeValue($event);
  }

  tooglePicker() {
    if(!this.disabled) {
      if(this.value && this.validateAfterChange) {
        this.value = new Date();
      }
      this.datepicker.toggle();

    }
  }

  writeValue(value: Date) {
    super.writeValue(value);
    this.formattedValueForView = this.formatter.formatValue(value);
    this.updateValue();
    this.validateAfterChange();
  }


  updateValue() {
    if (this.formatter.isValid(this.formattedValueForView)) {
      this.value = this.formatter.parseFormattedValue(this.formattedValueForView);
    } else {
      this.value = this.formattedValueForView;
    }
  }

  validate(c: AbstractControl): any {
    return this.validateAfterChange();
  };

  validateAfterChange() {
    if (this.value && !this.formatter.isValid(this.formattedValueForView)) {
      return {WrongDateFormat: true};
    }
  }
}


