import {Directive, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewContainerRef} from '@angular/core';
import * as _ from "lodash";
import {ViewRef} from "@angular/core/src/linker/view_ref";

@Directive({
  selector: '[renderTemplate]'
})
export class RenderTemplateDirective implements OnInit, OnChanges {
  @Input()
  renderTemplate: TemplateRef<any>;

  @Input()
  renderTemplateWithContext: any;

  private lastContext: any;
  private templateView: ViewRef = null;

  constructor(private viewContainer: ViewContainerRef) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!_.isEqual(this.renderTemplateWithContext, this.lastContext)) {
      this.lastContext = this.renderTemplateWithContext;
      this.reloadViewInContainer();
    }
  }

  ngOnInit(): void {
    this.reloadViewInContainer();
  }

  private reloadViewInContainer() {
    if (!this.renderTemplate) {
      return;
    }

    this.removeTemplateFromContainer(this.templateView);
    const tempView = this.renderTemplate.createEmbeddedView(this.renderTemplateWithContext);
    this.templateView = this.viewContainer.insert(tempView);
  }

  private removeTemplateFromContainer(viewRef: ViewRef) {
    if (viewRef) {
      let index = this.viewContainer.indexOf(viewRef);
      this.viewContainer.remove(index)
    }
  }
}
