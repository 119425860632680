import { Pipe, PipeTransform } from '@angular/core';
import * as _ from "lodash";
import {findIndex} from "rxjs/internal/operators";

@Pipe({name: 'groupByWithIndex'})
export class GroupByWithIndexPipe implements PipeTransform {
  transform(collection: Array<any>, property: string): Array<any> {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if(!collection) {
      return null;
    }

    const groupedCollection = collection.reduce((previous, current)=> {
      let value: any = _.property(property)(current);
      if(!previous[value]) {
        previous[value] = [current];
      } else {
        previous[value].push(current);
      }

      return previous;
    }, {});

    return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key].map(element => ({index: collection.findIndex((item) => item.id == element.id), item: element })) }));
  }
}