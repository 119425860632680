import {Pipe, PipeTransform} from '@angular/core';
import * as moment from "moment";

@Pipe({
  name: 'timeDiff'
})
export class TimeDiffPipe implements PipeTransform {

  transform(startDate: Date, endDate: Date): any {
    if(!startDate) {
      return '';
    }

    if(!endDate) {
      endDate = new Date();
    }

    let monthDifference =  Math.floor(moment(new Date(endDate)).diff(new Date(startDate), 'months', true));

    let endOfPrevMonth = new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth() + monthDifference, 0);
    let daysDifference = moment(new Date(endDate)).diff(endOfPrevMonth, 'days', true);
    monthDifference = monthDifference + (daysDifference >= 16 ? 1 : 0);

    if(monthDifference > 12) {
      const years: number =  Math.floor(monthDifference / 12);
      let months: number = Math.floor((monthDifference - years * 12));
      return Number.parseFloat(`${years}.${months}`);
    }

    if (monthDifference < 0) {
      monthDifference = 0;
    }

    return `${monthDifference} M`;
    
  }

}
