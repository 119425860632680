export interface Links {
  [name: string]: {
    href: string;
    meta: {};
  };
}

export class FullResourcesModel<MODEL> implements Iterable<MODEL> {

  length() {
    return this.data.length;
  }

  [Symbol.iterator]() {
    let pointer = 0;
    let items = this.data;

    return {
      next(): IteratorResult<MODEL> {
        if (pointer < items.length) {
          return {
            done: false,
            value: items[pointer++]
          }
        } else {
          return {
            done: true,
            value: null
          }
        }
      }
    }
  }

  data: MODEL[];
  links: Links;
  meta: { [key: string]: Object };

  constructor(data: MODEL[], links: Links, meta: any) {
    this.data = data;
    this.links = links;
    this.meta = meta;
  }
}