import * as _ from "lodash";
import {EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {NgForm} from "@angular/forms";
import {Observable, from, of} from "rxjs";
import {ElementBase} from "./element-base";
import {NetworkJsonApiService} from "../../../services/jsonapi/network-jsonapi.service";
import {ArgumentTypes} from "./argument-types";
import {flatMap} from "rxjs/operators";

export class AbstractMultiOptionComponent<T> extends ElementBase<T> implements OnChanges {

  @Input()
  options: any[];

  @Input()
  attributeNameForOption: string;

  @Output()
  optionChange = new EventEmitter();

  @Input()
  compareFn: any[];

  @Input()
  shouldBeTranslated: boolean = false;

  protected innerOptionsList: any[] = [];

  constructor(protected  ngForm: NgForm, protected  networkService: NetworkJsonApiService) {
    super(ngForm);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['options']) {
      return;
    }

    this.reloadOptionsList(changes['options'].currentValue).subscribe((options) => {
      this.innerOptionsList = options;
    });
    this.informAboutChangingOption();
  }

  reloadOptionsList(options: string | any[]): Observable<any> {
    return from([options])
      .pipe(
        flatMap(options => {
          if (ArgumentTypes.isString(options)) {
            return this.networkService.getResources(<string>options);
          }
          return of(options);
        })
      );
  }

  informAboutChangingOption() {
    this.optionChange.emit(this.value);
  }

  toLabel(item: any): string {
    if (typeof item === 'object' && this.attributeNameForOption) {
      let values = _.map(_.split(this.attributeNameForOption, ' '), (attributeNameForOption) => {
        return `${_.property(attributeNameForOption)(item)}`
      });
      return _.join(values, ' ');
    }

    return item;
  }

  public defaultCompareFn(option: any, value: any) {
    if (!value) {
      return false;
    }

    if (option === value) {
      return true;
    }

    if (ArgumentTypes.isSimpleType(option)) {
      return false;
    }

    let idOpt = _.property('id')(option);
    let idValue = _.property('id')(value);
    if (idOpt === idValue && idOpt) {
      return true;
    }

    return _.matches(value)(option);
  }

}
