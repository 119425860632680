import {NgForm} from '@angular/forms';
import {ValueAccessorBase} from './value-accessor';
import {Input, OnInit} from '@angular/core';
import * as _ from 'lodash';

export abstract class ElementBase<T> extends ValueAccessorBase<T> implements OnInit {

  @Input() public name: string;
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public role: string;

  public identifier: string;

  constructor(public formDirective: NgForm) {
    super();
  }

  ngOnInit() {
    if (_.isEmpty(this.placeholder)) {
      this.placeholder = '';
    }

    this.identifier = `${this.name}_${ Math.random()}`;
  }

  trackByIndex(index: number, item: any) {
    return index;
  }

  trackByItemId(index: number, item: any) {
    return item.id;
  }
}
