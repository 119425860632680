import {Component, ViewEncapsulation, ViewChild, ElementRef, Renderer2, Input} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, Validator} from "@angular/forms";
import {AbstractControl} from "@angular/forms/src/model";
import {ElementBase} from "../abstractComponent/element-base";
import {TimeFormatter} from "./time-formatter";
import * as _ from "lodash";

@Component({
  selector: 'pd-form-time',
  template: require('./form-time.component.html'),
  styles: [require('./form-time.component.scss')],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: FormTimeComponent, multi: true},
    {provide: NG_VALIDATORS, useExisting: FormTimeComponent, multi: true}
  ]
})
export class FormTimeComponent extends ElementBase<any> implements Validator {

  @Input()
  public disabled: boolean = false;
  @Input()
  public required: boolean = false;
  @Input()
  public invalid: boolean = false;

  public maskObject: Object;

  @ViewChild('time') time: ElementRef;

  private static DELETE_CODE = 46;
  private static BACKSPACE_CODE = 8;
  private static ARROW_UP_CODE = 38;
  private static ARROW_DOWN_CODE = 40;
  private static TIME_STEP = 5;

  protected formatter: TimeFormatter = new TimeFormatter();
  protected formattedValueForView: any = null;

  private canSetTime: boolean = false;

  constructor(public formDirective: NgForm, private renderer: Renderer2) {
    super(formDirective);
  }

  ngOnInit() {
    this.maskObject = {
      mask: [/[0-9]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
      guide: true,
      keepCharPositions: false
    }
  }

  writeValue(value: Date) {
    super.writeValue(value);
    this.formattedValueForView = this.formatter.formatValue(value);
  }

  checkFieldValue() {
    this.canSetTime = true;
  }

  checkValue() {
    if(!this.canSetTime || _.isNull(this.formattedValueForView) || !_.isNull((this.formattedValueForView.match(/[0-9][0-9]:[0-9][0-9]/)))) {
      return;
    }

    let firstLetter: string = this.formattedValueForView[0];
    let input = this.renderer.selectRootElement(this.time["nativeElement"]);

    if(_.toInteger(firstLetter) > 2) {
      this.formattedValueForView = `0${firstLetter}:00`;
      this.canSetTime = false;
      setTimeout(() => {
        input.setSelectionRange(3, 3);
      });
    }

    if(_.toInteger(firstLetter) <= 2) {
      this.formattedValueForView = `${firstLetter}0:00`;
      this.canSetTime = false;
      setTimeout(() => {
        input.setSelectionRange(1, 1);
      });
    }
  }

  updateValue() {
    if (this.formatter.isValid(this.formattedValueForView)) {
      this.value = this.formatter.parseFormattedValue(this.formattedValueForView);
    } else {
      this.value = this.formattedValueForView;
      this.checkValue();
    }
  }

  validate(c: AbstractControl): any {
    if (this.value && !this.formatter.isValid(this.formattedValueForView)) {
      return {WrongTimeFormat: true};
    }

    return null;
  };

  onKey(event: KeyboardEvent) {

    if(this.isDeleteKeyPress(event.keyCode)) {
      this.formattedValueForView = null;
      return;
    }

    if(!this.isArrowKeyPress(event.keyCode)) {
      return;
    }

    if(_.isEmpty(this.formattedValueForView)) {
      this.formattedValueForView = '00:00';
    }

    let date: Date = this.formatter.toDate(this.formattedValueForView);

    if(event.keyCode == FormTimeComponent.ARROW_UP_CODE) {
      date.setMinutes(date.getMinutes() + FormTimeComponent.TIME_STEP);
    } else if(event.keyCode == FormTimeComponent.ARROW_DOWN_CODE) {
      date.setMinutes(date.getMinutes() - FormTimeComponent.TIME_STEP);
    }

    this.writeValue(date);
  }

  private isArrowKeyPress(keyCode: number): boolean {
    return keyCode == FormTimeComponent.ARROW_UP_CODE || keyCode == FormTimeComponent.ARROW_DOWN_CODE;
  }

  private isDeleteKeyPress(keyCode: number): boolean {
    return keyCode == FormTimeComponent.DELETE_CODE || keyCode == FormTimeComponent.BACKSPACE_CODE;
  }

}


