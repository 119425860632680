import {TimeConverter} from "../../../services/jsonapi/converters/attributes/TimeConverter";

export class TimeFormatter {

  private timeConverter: TimeConverter = TimeConverter.formatForView();

  formatValue(value: Date): string {
    return this.timeConverter.formatValue(value);
  }

  parseFormattedValue(value: string): Date {
    if (value && this.timeConverter.isValidDate(value)) {
      return this.timeConverter.convertToType(value);
    }

    return null;
  }

  isValid(formattedValue: string) {
    return this.timeConverter.isValidDate(formattedValue);
  }

  toDate(value: string): Date {
    let date: Date = new Date();
    date.setHours(parseInt(value.substring(0,2)));
    date.setMinutes(parseInt(value.substring(value.length -2)));
    date.setSeconds(0,0);
    return date;
  }
}