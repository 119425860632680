import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';

@Directive({
  selector: '[pdFormValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: FormValidatorDirective, multi: true}]
})
export class FormValidatorDirective implements Validator {

  @Input('pdFormValidator')
  public pdFormValidator: ValidatorFn;


  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.pdFormValidator) {
      return this.pdFormValidator(control);
    }
    return null;
  }
}
