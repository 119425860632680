import * as moment from "moment";

export class LongTimeFormatter {

  formatValue(value: number, zfill: number = 3): string {
    if(!value) {
      return;
    }

    let date: Date = new Date;
    date.setSeconds(date.getSeconds() + value);

    let hours = Math.floor(moment(date).diff(new Date(), 'hours', true));
    let minutes: number = (value - (hours * 3600)) / 60;
    return `${this.zfill(hours, zfill)}:${this.zfill(minutes, 2)}`;

  }

  zfill(num: number, len: number): string {
    return (Array(len).join("0") + num).slice(-len);
  }

  toNumber(value: string): number {
    let [hours, minutes ] = value.split(':').map((part) => Number(part));
    return (hours * 3600) + (minutes * 60);
  }

}