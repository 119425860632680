import {Component, Input} from '@angular/core';
import {AbstractControl} from "@angular/forms/src/model";
import {NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, Validator} from "@angular/forms";
import {MoneyFormatter} from "./money-formatter";
import {ElementBase} from "../abstractComponent/element-base";

@Component({
  selector: 'pd-form-money',
  template: require('./form-money.component.html'),
  styles: [require('./form-money.component.scss')],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: FormMoneyComponent, multi: true},
    {provide: NG_VALIDATORS, useExisting: FormMoneyComponent, multi: true}
  ]
})
export class FormMoneyComponent extends ElementBase<any> implements Validator {

  private static MAX_VALUE: number = 999999999999999;
  private static MIN_VALUE: number = -FormMoneyComponent.MAX_VALUE;

  @Input()
  public maxValue: number = FormMoneyComponent.MAX_VALUE;

  @Input()
  public minValue: number = FormMoneyComponent.MIN_VALUE;

  protected formatter: MoneyFormatter = new MoneyFormatter();

  constructor(public formDirective: NgForm) {
    super(formDirective);
  }

  protected formattedValueForView: any = null;


  writeValue(value: string) {
    super.writeValue(value);
    this.formattedValueForView = this.formatter.formatValue(value);
  }

  set formattedValue(value) {
    this.formattedValueForView = value;
    if (this.formatter.isValid(this.formattedValueForView)) {
      this.value = this.formatter.parseFormattedValue(this.formattedValueForView);
    }
    else{
      this.value = value;
    }
  }

  get formattedValue() {
    return this.formattedValueForView;
  }

  reformatInputContent() {
    this.formattedValueForView = this.formatter.formatValue(this.value);
  }

  validate(c: AbstractControl): any {
    if (!this.formattedValueForView) {
      return null;
    }

    if (!this.formatter.isValid(this.formattedValueForView)) {
      return {WrongMoneyFormat: true};
    }

    const value: number = this.formatter.parseFormattedValue(this.formattedValueForView);
    if (value > this.maxValue) {
      return {MaxValue: {requiredMax: this.formatter.formatValue(this.maxValue)}}
    }

    if (value < this.minValue) {
      return {MinValue: {requiredMin: this.formatter.formatValue(this.minValue)}}
    }

    return null;
  };
}
