import { NetworkJsonApiService } from "../framework/services/jsonapi/network-jsonapi.service";
import {AccountSettingsModel} from "../transferObject";
import { ResourceUrlService } from "./resource-url.service";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AccountSettingsService {

  static LANGUAGE_KEY: string = 'language';
  static LOCATION_KEY: string = 'location';
  static CLOCK_PROJECT_KEY: string = 'clock_project';

  constructor(private networkService: NetworkJsonApiService, private translateService: TranslateService) {
  }

  loadSettings() {
    this.networkService.getResource(ResourceUrlService.ACCOUNT_SETTINGS_PATH).subscribe((accountSettings: AccountSettingsModel) => {
      this.storeSettings(accountSettings.language, accountSettings.location);
      this.setLanguage();
    });
  }

  getCurrentLanguage(): string {
    return localStorage.getItem(AccountSettingsService.LANGUAGE_KEY);
  }

  getCurrentLocation(): string {
    return localStorage.getItem(AccountSettingsService.LOCATION_KEY);
  }

  setLanguage() {
    let language: string = this.getCurrentLanguage();
    this.translateService.setDefaultLang(language);
    this.translateService.use(language);
  }

  storeSettings(language: string, location: string) {
    localStorage.setItem(AccountSettingsService.LANGUAGE_KEY, language);
    localStorage.setItem(AccountSettingsService.LOCATION_KEY, location);
  }
}
