import {Component} from '@angular/core';
import {NG_VALUE_ACCESSOR, NgForm} from "@angular/forms";
import {AbstractMultiOptionComponent} from "../abstractComponent/abstract-multi-option-component";
import {NetworkJsonApiService} from "../../../services/jsonapi/network-jsonapi.service";

@Component({
  selector: 'pd-form-radio',
  template: require('./form-radio.component.html'),
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: FormRadioComponent, multi: true}]
})
export class FormRadioComponent extends AbstractMultiOptionComponent<any> {
  constructor(protected ngForm: NgForm, protected networkService: NetworkJsonApiService) {
    super(ngForm, networkService);
  }
}
