import {AbstractConverter} from "./AbstractConverter";
import * as moment from "moment";
import * as _ from "lodash";


export class DateConverter implements AbstractConverter<string, Date> {

  public static readonly SERVER_DATE_FORMAT = 'DD.MM.YYYY';
  public static readonly VIEW_DATE_FORMAT = 'DD.MM.YYYY';
  public static readonly MONTH_YEAR_DATE_FORMAT = 'MM.YYYY';
  public static readonly INFINITY_WORD = 'wordInfinity';

  constructor(private dateFormat: string) {
  }

  convertToType(value: string): Date {
    const dateWrapper = moment(_.trim(value), this.dateFormat, true);
    if (dateWrapper.isValid()) {
      return dateWrapper.toDate();
    }
    return null;
  }

  formatValue(value: Date, args?: any): string {
    if (!value) {
      return args == 'nullAsInfinity' ? DateConverter.INFINITY_WORD : null;
    }
    return moment(value).format(this.dateFormat);
  }

  public isValidDate(value: string) {
    return moment(_.trim(`${value}`), this.dateFormat, true).isValid();
  }

  public static formatForJson(): DateConverter {
    return new DateConverter(DateConverter.SERVER_DATE_FORMAT);
  }

  public static formatForView(): DateConverter {
    return new DateConverter(DateConverter.VIEW_DATE_FORMAT);
  }

  public static monthYear(): DateConverter {
    return new DateConverter(DateConverter.MONTH_YEAR_DATE_FORMAT);
  }
}
