import {Component, Input, OnInit} from '@angular/core';


import {Subject} from "rxjs";
import {NetworkJsonApiService} from "baza/app/framework/services/jsonapi/network-jsonapi.service";
import {OfferModel} from "baza/app/transferObject";
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'pd-offer-page',
  template: require('./offer-page.component.html'),
  styles: [require('./offer-page.component.scss')]
})
export class OfferPageComponent implements OnInit {

  public destroyed = new Subject<any>();
  public offer: OfferModel = null;

  constructor(private networkService: NetworkJsonApiService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.getOffer(params.inUrl);
    });



  }


  getOffer(inUrl: string) {
    this.networkService.getResource('/api/offers/' + inUrl).subscribe((offer: OfferModel) => {
      this.offer = offer;
    });
  }

}
