import {AbstractResourceModel} from "./common/abstract-resource-model";
import {Model} from "../framework/services/jsonapi/common";

@Model('access_rights')
export class AccessRightModel extends AbstractResourceModel {

  right: string = null;

  constructor() {
    super("/api/access_rights");
  }

}