import {NG_VALUE_ACCESSOR, NgForm} from "@angular/forms";
import {Component, Input} from "@angular/core";
import {ElementBase} from "../abstractComponent/element-base";


@Component({
  selector: 'pd-form-textarea',
  styles: [require('./form-textarea.component.scss')],
  template: require('./form-textarea.component.html'),
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: FormTextareaComponent, multi: true}
  ]
})
export class FormTextareaComponent extends ElementBase<string> {
  @Input()
  public row: number = 4;

  @Input()
  public cols: number = 50;

  constructor(public formDirective: NgForm) {
    super(formDirective);
  }
}