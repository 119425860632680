import {AccountModel} from "../transferObject";
import {AuthService} from "./auth.service";
import {Injectable} from "@angular/core";

@Injectable()
export class AccountRoleService {

  static readonly EMPLOYEE_ROLE: string = 'Account';

  constructor(private authService: AuthService) { }

  isCurrentUserAccount(): boolean {
    return this.authService.getCurrentRoleName() === AccountRoleService.EMPLOYEE_ROLE;
  }


}
