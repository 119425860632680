import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'pd-form-header',
  template: require('./form-header.component.html')
})
export class FormHeaderComponent implements OnInit {

  static HEADER_FOR_NEW: string = 'formAddNewResourceHeader';
  static HEADER_FOR_EDITING: string = 'formEditResourceHeader';

  @Input()
  public isNew: boolean;

  @Input()
  public resourceDescriptionKey: string;

  @Input()
  public translationKey: string;

  public translatedResourceDescription: string;

  constructor(protected translateService: TranslateService) {
  }

  ngOnInit() {
    this.doTranslation();
  }

  private doTranslation() {
    let translationKey = FormHeaderComponent.HEADER_FOR_NEW;

    if(this.translationKey) {
      translationKey = this.translationKey;
    }
    else{
      if (!this.isNew) {
        translationKey = FormHeaderComponent.HEADER_FOR_EDITING;
      }
    }

    this.translateService.get(this.resourceDescriptionKey, {}).subscribe((translatedResourceDesc: string) => {
      this.translateService.get(translationKey, {resourceName: translatedResourceDesc}).subscribe((translation: string) => {
        this.translatedResourceDescription = translation;
      });
    });
  }
}
