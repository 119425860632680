import {ActivatedRouteSnapshot, CanActivate, Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {AccessRightService} from "../framework/services/resource/access-right.service";
import {Subscription} from "rxjs";

@Injectable()
export class AccessRightGuard implements CanActivate {

  private permissionUpdateListener: Subscription;

  constructor(private accessRight: AccessRightService, private router: Router) {
    this.permissionUpdateListener = this.accessRight.setAccessRightUpdateListener(() => {
      this.checkCurrentRouteCanActivateState();
    });
  }

  canActivate(route: ActivatedRouteSnapshot) {
    let right = route.data["right"] as string;

    if(this.accessRight.loaded()) {
      return this.accessRight.hasRight(right);
    }
    else{
      return true;
    }

  }

  checkCurrentRouteCanActivateState() {
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(this.router.routerState.snapshot.url);
  }

}
