import {Directive, Input} from '@angular/core';
import {AsyncValidator, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms';
import {AbstractControl} from '@angular/forms/src/model';
import {Observable} from 'rxjs';

@Directive({
  selector: '[pdFormAsyncValidator]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: PdFormAsyncValidatorDirective, multi: true}]
})
export class PdFormAsyncValidatorDirective implements AsyncValidator {

  @Input('pdFormAsyncValidator')
  private pdFormAsyncValidator: AsyncValidatorFn;


  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    if (this.pdFormAsyncValidator) {
      return this.pdFormAsyncValidator(control);
    }
    return null;
  }

}
