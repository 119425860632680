import { Pipe, PipeTransform } from '@angular/core';
import * as _ from "lodash";

@Pipe({name: 'group'})
export class GroupPipe implements PipeTransform {
  transform(collection: Array<any>, callback: any): any {

    if(!collection) {
      return null;
    }

    let groupedMap = new Map();

    _.forEach(collection, (model) => {
      let key = callback(groupedMap, model);

      if(groupedMap.has(key)) {
        let newList = groupedMap.get(key);
        newList.push(model);
        groupedMap.set(key, newList);
      }
      else{
        groupedMap.set(key, [model]);
      }
    });

    let data = [];
    groupedMap.forEach((v, k) => {
      data.push({ key: k, value: v});
    });

    return data;

  }
}