import {FormGroup} from "@angular/forms";
import {of, Observable, throwError} from "rxjs";
import {AbstractResourceLifecycleListener} from "./abstract-resource-lifecycle-listener";
import * as _ from "lodash";


export abstract class AbstractResourceLifecycle<MODEL> {

  private listeners: AbstractResourceLifecycleListener<MODEL> [] = [];

  public beforeLoadingModelFromServer(url: string): Observable<string> {
    console.log('beforeLoadingResourceFromServer');
    this.listeners.forEach((listener) => listener.beforeLoadingModelFromServer(url));
    return of(url);
  }

  public afterLoadingModelFromServerWithSuccess(model: MODEL) {
    console.log('afterLoadingModelFromServerWithSuccess');
    this.listeners.forEach((listener) => listener.afterLoadingModelFromServerWithSuccess(this.clone(model)));
    return of(model);
  }


  public afterLoadingModelFromServerWithError(error: any) {
    console.log('afterLoadingModelFromServerWithError');
    this.listeners.forEach((listener) => listener.afterLoadingModelFromServerWithError(this.clone(error)));
    return throwError(error)
  }

  public beforeFillingForm(form: FormGroup, model: MODEL): Observable<MODEL> {
    console.log('beforeLoadingModelToForm');
    this.listeners.forEach((listener) => listener.beforeFillingForm(this.clone(model)));
    return of(model);
  }

  public afterFillingForm(form: FormGroup, model: MODEL): Observable<MODEL> {
    console.log('afterLoadingModelToForm');
    this.listeners.forEach((listener) => listener.afterFillingForm(this.clone(model)));
    return of(model);
  }

  public beforeFormValidation(form: FormGroup): Observable<any> {
    console.log('beforeFormValidation');
    this.listeners.forEach((listener) => listener.beforeFormValidation());
    return of(form);
  }

  public afterValidationFinishedWithSuccess(form: FormGroup, model: MODEL): Observable<any> {
    console.log('afterValidationFinishedWithSuccess');
    this.listeners.forEach((listener) => listener.afterValidationFinishedWithSuccess());
    Object.assign(model, form.value);
    return of(model);
  }

  public afterValidationFinishedWithError(error: any): Observable<any> {
    console.log('afterValidationFinishedWithError');
    this.listeners.forEach((listener) => listener.afterValidationFinishedWithError((this.clone(error))));
    return throwError(error);
  }


  public beforeSavingModelOnServer(model: MODEL): Observable<MODEL> {
    console.log('beforeSavingModelOnServer');
    this.listeners.forEach((listener) => listener.beforeSavingModelOnServer((this.clone(model))));
    return of(model);
  }


  public afterSavingModelOnServerWithSuccess(model: MODEL, silentMode: boolean = false): Observable<MODEL> {
    console.log('afterSavingModelOnServerWithSuccess');
    if(!silentMode) {
      this.listeners.forEach((listener) => listener.afterSavingModelOnServerWithSuccess((this.clone(model))));
    }
    else{
      console.log('silent mode, listeners were not informed');
    }
    return of(model);
  }

  public afterSavingModelOnServerWithError(error: any): Observable<{ any }> {
    console.log('afterSavingModelOnServerWithError' + JSON.stringify(error));
    this.listeners.forEach((listener) => listener.afterSavingModelOnServerWithError(this.clone(error)));
    return throwError(error);
  }

  registerListener(listener: AbstractResourceLifecycleListener<MODEL>) {
    this.listeners.push(listener);
  }

  unregisterListener(item: AbstractResourceLifecycleListener<MODEL>) {
    _.remove(this.listeners, (listener) => listener === item);
  }

  private clone<T>(data: T): T {
    return <T>Object.assign({}, data);
  }

}