import {AbstractResourceModel} from './common/abstract-resource-model';
import {Model} from '../framework/services/jsonapi/common';

@Model('offers')
export class OfferModel extends AbstractResourceModel {
  title: string = null;
  shortTitle: string = null;
  price: string = null;
  imagePath: string = null;
  inUrl: string = null;
  content: string = null;

  constructor() {
    super("/api/offers");
  }

  toString(): string {
    return this.title;
  }
}
