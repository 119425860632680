import {Model} from "../framework/services/jsonapi/common";
import {AbstractResourceModel} from "./common/abstract-resource-model";

@Model('languages')
export class LanguageModel extends AbstractResourceModel {
  lang: string = null;

  constructor() {
    super('/api/languages');
  }
}