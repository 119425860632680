import { Routes } from "@angular/router";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { OnlyLoggedUsersGuard } from "./guards/only-logged-users.guard";
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component";
import {OfferPageComponent} from "baza/app/pages/offer-page/offer-page.component";
import {StaticContentComponent} from "baza/app/components/static-content/static-content.component";

export const DefaultRoute = 'dashboard';
export const LoginRoute = 'login';

export const appRoutes: Routes = [
  { path: LoginRoute, component: LoginPageComponent },
  { path: DefaultRoute, component: DashboardPageComponent },
  { path: 'offer/:inUrl', component: OfferPageComponent },
  { path: 'page/:inUrl', component: StaticContentComponent },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '*', redirectTo: '/dashboard', pathMatch: 'full' }
];
