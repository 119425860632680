import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AccessRightService} from "../services/resource/access-right.service";
import {Subscription} from "rxjs";

@Directive({
  selector: '[ifHasPermission]'
})
export class IfHasPermissionDirective {

  private permissionUpdateListener: Subscription = null;
  private right: string = null;

  constructor(
      private templateRef: TemplateRef<any>,
      private viewContainer: ViewContainerRef,
      private accessRight: AccessRightService) {

    this.permissionUpdateListener = this.accessRight.setAccessRightUpdateListener(() => {
      this.checkRight();
    });
  }

  @Input()
  set ifHasPermission(right) {
    this.right = right;
    this.checkRight();
  }

  checkRight() {
    if(this.accessRight.hasRight(this.right)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  ngOnDestroy(): void {
    this.viewContainer.detach();
    if(this.permissionUpdateListener) {
      this.permissionUpdateListener.unsubscribe();
    }
  }

}
