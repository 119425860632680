import {throwError as observableThrowError, Observable, of} from 'rxjs';
import {catchError, flatMap, mergeMap} from "rxjs/operators";
import {AbstractControl, FormGroup, NgForm} from '@angular/forms';
import * as _ from 'lodash';
import {AbstractResourceLifecycle} from "./abstract-resource-lifecycle";
import {NetworkJsonApiService} from "../../../services/jsonapi/network-jsonapi.service";
import {AfterViewChecked, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {ServerValidationError} from "../../../../transferObject/errors/ServerValidationError";
import {FormValidationMsgComponent} from "../form-validation-msg/form-validation-msg.component";

export abstract class AbstractResourceEditor<MODEL> extends AbstractResourceLifecycle<MODEL> implements OnInit, AfterViewChecked {

  public static SILENT_SAVE: string = 'silentSave';

  @Input()
  public resource: MODEL | string;

  @Input()
  public reloadModel: boolean = true;

  @Output()
  public save = new EventEmitter();

  @ViewChild(NgForm)
  protected formDirective: NgForm;
  public model: MODEL;

  private initializedFormInputQuantity = 0;
  public saveWithoutClose: boolean = false;

  constructor(protected networkService: NetworkJsonApiService) {
    super();
  }

  ngOnInit() {
    setTimeout(() => this.loadResource(this.resource), 0);
  }

  ngAfterViewChecked(): void {
    if (!this.model) {
      return;
    }
    if (this.initializedFormInputQuantity !== _.size(this.formDirective.form.controls)) {
      this.pushAgainModelToFormInput();
    }
  }

  public loadResource(resource: string | MODEL) {
    let action = of(resource);

    if (this.shouldBeLoadedFromServer(this.resource)) {
      action = of(this.getResourceUrl(this.resource))
        .pipe(
          flatMap((url: string) => this.beforeLoadingModelFromServer(url)),
          flatMap((url: string) => this.loadingModelFromServer(url)),
          catchError((error) => this.afterLoadingModelFromServerWithError(error)),
          flatMap((model: MODEL) => this.afterLoadingModelFromServerWithSuccess(model))
        );
    }

    action
      .pipe(
        flatMap((model: MODEL) => this.beforeFillingForm(this.formDirective.form, model)),
        flatMap((model: MODEL) => this.fillForm(this.formDirective.form, model)),
        flatMap((model: MODEL) => this.afterFillingForm(this.formDirective.form, model))
      )
      .subscribe((model: MODEL) => {
        this.model = model;
        this.initializedFormInputQuantity = _.size(this.formDirective.form.controls);
      }, (error) => {
        throw new Error(error);
      })
  }

  private shouldBeLoadedFromServer(resource: MODEL | string) {
    return !!this.reloadModel && resource && !this.isNew(resource) && typeof this.getResourceUrl(resource) === 'string';
  }

  private isNew(resource: string | MODEL) {
    if (typeof resource === "string") {
      return false;
    }
    return (<any>resource).isNew();
  }


  submitForm(event: Event) {
    event.preventDefault();

    of(this.formDirective.form)
      .pipe(
        flatMap((form: FormGroup) => this.beforeFormValidation(form)),
        flatMap((form: FormGroup) => this.runValidation(form)),
        catchError((form: FormGroup) => this.afterValidationFinishedWithError(form)),
        flatMap((form: FormGroup) => this.afterValidationFinishedWithSuccess(form, this.model)),
        flatMap((model: MODEL) => this.beforeSavingModelOnServer(model)),
        mergeMap((model: MODEL) => this.saveModelOnServer(model, this.getResourceUrl(model))),
        catchError((error: any) => this.afterSavingModelOnServerWithError(error)),
        flatMap((model: MODEL) => this.afterSavingModelOnServerWithSuccess(model, event.type === AbstractResourceEditor.SILENT_SAVE))
      )
      .subscribe((model: MODEL) => {
        this.model = model;
          this.save.next(model);
      }, (error) => {
        console.warn(error);
      });
  }

  public saveModelOnServer(model: MODEL, url: string): Observable<any> {
    return this.networkService.saveResource<MODEL>(url, model);
  }

  public afterSavingModelOnServerWithError(error: any) {
    this.showServerValidationError(error.attrValidationErrors);
    return super.afterSavingModelOnServerWithError(error);
  }

  public trackById(item: any) {
    return item.id;
  }

  public trackByIndex(index: number) {
    return index;
  }

  private pushAgainModelToFormInput() {
    setTimeout(() => {
      this.pushModelToForm(this.model).subscribe(() => {
        this.initializedFormInputQuantity = _.size(this.formDirective.form.controls)
      });
    });
  }

  private pushModelToForm(model: MODEL): Observable<MODEL> {
    return of(model)
      .pipe(
        flatMap((model: MODEL) => this.beforeFillingForm(this.formDirective.form, model)),
        flatMap((model: MODEL) => this.fillForm(this.formDirective.form, model)),
        flatMap((model: MODEL) => this.afterFillingForm(this.formDirective.form, model))
      );
  }

  private getResourceUrl(model: MODEL | string): string {
    if (typeof model === 'string') {
      return model;
    }
    const temp: any = <any>model;
    if (temp && temp.links && temp.links.self && temp.links.self.href) {
      return temp.links.self.href;
    }

    return null;
  }

  private fillForm(form: FormGroup, model: MODEL): Observable<MODEL> {
    console.log('loadModelToForm');
    _.forEach(form.controls, (input: AbstractControl, key: string) => {
      input.patchValue(_.get(model, key));
    });

    return of(model);
  }


  private runValidation(form: FormGroup): Observable<FormGroup> {
    form.updateValueAndValidity();
    if (!form.valid) {
      return observableThrowError(new Error(`Form input [name: ${this.findFieldWithErrorInForm(form)}] doesn't pass validation `));
    }

    return of(form);
  }

  private findFieldWithErrorInForm(form: FormGroup): string[] {
    const inputs: string[] = [];

    if (form.errors) {
      inputs.push('form');
    }

    _.forEach(form.controls, (input: AbstractControl, key: string) => {
      if (input.errors) {
        inputs.push(key)
      }
    });

    return inputs;
  }

  private loadingModelFromServer(resourceUrl: string): Observable<MODEL> {
    console.log('loadingModelFromServer');
    return this.networkService.getResource<MODEL>(resourceUrl);
  }

  propertyIsEnumerable(v: string): boolean {
    return super.propertyIsEnumerable(v);
  }

  private showServerValidationError(attrValidationErrors: ServerValidationError[]) {
    _.forEach(attrValidationErrors, (error: ServerValidationError) => {
      const entry = {};
      entry[FormValidationMsgComponent.VALIDATION_ERR_MSG_PREFIX] = {msg: error.msg};
      const control = this.formDirective.form.get(_.camelCase(error.attribute));
      if(control) {
        control.setErrors(entry);
      }
    })

  }
}
