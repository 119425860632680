import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";

import {AuthService} from "../auth.service";
import {ModalWindowService} from "../modalWindow/modal-window.service";

@Injectable()
export class RedirectInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService, private modalWindowService: ModalWindowService) {

  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap((event: HttpEvent<any>) => {
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.authService.deleteTokenWithSelfUrl();
              if (!this.router.url.match('/login') && !this.router.parseUrl(this.router.url).queryParams['next']) {
                this.modalWindowService.hideModal();
                this.router.navigate(['login'], {queryParams: {next: this.router.url}});
              }
            }
          }
        })
      );
  }
}
