import {Injectable} from '@angular/core';
import {ConversionService} from './conversion.service';
import {Observable, throwError, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, flatMap, map} from 'rxjs/operators';


import * as _ from 'lodash';
import {JsonApiResource, JsonApiWrapper} from "./jsonapi";
import {ArgumentTypes} from "../../components/form/abstractComponent/argument-types";
import {ErrorResponse} from "../../../transferObject/errors/ErrorResponse";
import {FullResourcesModel} from "../../common/FullResourcesModel";

export const defaultHttpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/vnd.api+json'})
};

@Injectable()
export class NetworkJsonApiService {
  constructor(private conversionService: ConversionService, private http: HttpClient) {
  }

  getResource<T>(url: string, httpOptions = defaultHttpOptions): Observable<T> {
    return this.http.get<any>(url).pipe(
      map(val => JsonApiWrapper.of(val)),
      map(val => {
        return <T>this.conversionService.convertResponse(val);
      }),
      catchError((error) => this.showErrorOnConsole(error))
    )
  }

  getResources<T>(url: string, httpOptions = defaultHttpOptions): Observable<T[]> {
    return this.http.get<any>(url).pipe(
      map(val => JsonApiWrapper.of(val)),
      map(val => {
        if (val.isMultiResource()) {
          const wrappers = val.toScalarWrappers();
          return <T[]>_.map(wrappers, wrapper => {
            return this.conversionService.convertResponse<any>(wrapper);
          });
        } else {
          return [];
        }
      }),
      catchError((error) => this.showErrorOnConsole(error))
    )

  }

  getResourcesWithData<T>(url: string, httpOptions = defaultHttpOptions): Observable<FullResourcesModel<any>> {
    return this.http.get<any>(url).pipe(
      map(val => JsonApiWrapper.of(val)),
      map(val => {
        if (val.isMultiResource()) {
          const wrappers = val.toScalarWrappers();
          let data: any = <T[]>_.map(wrappers, wrapper => {
            return this.conversionService.convertResponse<any>(wrapper);
          });
          return new FullResourcesModel(data, val.getLinks(), val.getMeta());
        } else {
          return new FullResourcesModel([], null, null);
        }
      }),
      catchError((error) => this.showErrorOnConsole(error))
    )

  }

  putResource<T>(url: string, resource: any, included = [], httpOptions = defaultHttpOptions): Observable<T> {
    return of(resource).pipe(
      map(modelRes => this.conversionService.convertRequest(resource, [], included)),
      flatMap(requestBody => {
        return this.http.put<any>(url, requestBody, httpOptions);
      }),
      map(response => JsonApiWrapper.of(response)),
      map(response => <T>this.conversionService.convertResponse(response)),
      catchError((error) => this.showErrorOnConsole(error))
    );
  }

  saveResource<T>(url: string, resource: any, httpOptions = defaultHttpOptions): Observable<T> {
    let includedRefNames: string[] = this.makeIncludesList(resource);
    if (resource.id === null || (resource['id'].toString()).startsWith('-')) {
      return this.postResource<T>(url, resource, includedRefNames, httpOptions);
    }
    return this.putResource<T>(url, resource, includedRefNames, httpOptions);
  }

  postResource<T>(url: string, resource: any, included = [], httpOptions = defaultHttpOptions): Observable<T> {
    return of(resource).pipe(
      map(modelRes => this.conversionService.convertRequest(resource, [], included)),
      flatMap(requestBody => {
        return this.http.post<any>(url, requestBody, httpOptions);
      }),
      map(response => JsonApiWrapper.of(response)),
      map(response => <T>this.conversionService.convertResponse(response)),
      catchError((error) => this.showErrorOnConsole(error))
    )
  }

  deleteResource(url: string, httpOptions = defaultHttpOptions): Observable<JsonApiResource> {
    return this.http.delete<JsonApiResource>(url, httpOptions).pipe(
      catchError((error) => this.showErrorOnConsole(error))
    );
  }

  private makeIncludesList(resource: any): string [] {
    const includes: string[] = [];
    for (let attribute in resource) {
      if (attribute === 'links' || _.isEmpty(resource[attribute])) {
        continue;
      }
      if (ArgumentTypes.isObjectArray(resource[attribute]) || ArgumentTypes.isObject(resource[attribute])) {
        includes.push(attribute);
      }
    }
    return includes;
  }

  private showErrorOnConsole(error: any) {
    let errorResponse: ErrorResponse = this.conversionService.convertError<ErrorResponse>(error);
    return throwError(errorResponse);
  }
}

