import {Component, OnInit} from '@angular/core';
import {TokenModel} from "../../transferObject/TokenModel";
import {NavbarService} from "../../services/navbar.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'pd-login-page',
  template: require('./login-page.component.html'),
  styles: [require('./login-page.component.scss')]
})

export class LoginPageComponent implements OnInit {

  constructor(private navbarService: NavbarService, private authService: AuthService) {}

  public token: TokenModel = null;

  ngOnInit() {
    this.authService.deleteTokenWithSelfUrl();
    this.token = new TokenModel();
    this.navbarService.hide();
  }

}