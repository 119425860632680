import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'pd-table-column',
  template: require('./table-column.component.html'),
  styles: [require('./table-column.component.scss')],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableColumnComponent {
  @Input()
  attributeName: string | {[key in string] : string};

  @Input()
  attributeSubName: string | {[key in string] : string};

  @Input()
  tdClass: string = null;

  @Input()
  public sort: boolean = false;

  @ContentChild(TemplateRef, {read: TemplateRef})
  public templateForColumn: TemplateRef<any>;

}
